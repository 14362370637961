<script lang="ts">
  import { link } from 'svelte-spa-router';
  import { maskedParticipants, unmaskedParticipants } from '../../../api/api';

  import { trackEvent } from '../../util/snowplow';
  import Smiles from './Smiles.svelte';
  import Statistics from './Statistics.svelte';

  export let participantID: number = 0;
  export let participantState: string = '';

  $: unmaskedParticipantsCopy = [
    ...$unmaskedParticipants.filter((p) => !p.rescued),
  ].reverse();

  let points = $unmaskedParticipants.reduce((acc, participant) => {
    if (participant.unmasked && participant.correct) {
      return acc + 1;
    }
    return acc;
  }, 0);

  // Denne er kopiert fra forrige klient, litt usiker på om den er riktig.
  const getScoreboardText = (
    revealedParticipants: Maskorama.Participant[],
    isInListview = false
  ) => {
    let scoreBoardText = '';
    if (revealedParticipants.length > 0) {
      let correctCount = 0;
      let incorrectCount = 0;
      revealedParticipants.forEach((participant) => {
        if (participant.correct && participant.unmasked) {
          correctCount++;
          return;
        }
        if (!participant.correct && participant.unmasked) {
          incorrectCount++;
          return;
        }
      });

      scoreBoardText = `Du har ${correctCount} av ${
        correctCount + incorrectCount
      } riktige svar.`;
      if (isInListview) {
        scoreBoardText += ` Avslør flere deltakere for å oppdatere poengsummen.`;
      }
    }
    return scoreBoardText;
  };

  $: scoreBoardText = getScoreboardText(
    $unmaskedParticipants,
    participantID === 0
  );
</script>

<!-- wrap i position: relative for å ikke hoppe til toppen når skjermleser har fokus -->
<div style="position:relative;">
  <div class="sr-only">{scoreBoardText}</div>
</div>
<div class="scoreboard-wrapper" aria-hidden={true}>
  <div class="scoreboard__shadow-wrapper {participantState}">
    <div class="scoreboard {participantState}">
      <span class="scoreboard__content">
        <h3>Poengtavle</h3>
        <div class="dot-wrapper">
          {#each unmaskedParticipantsCopy as participant}
            {#if participant.id !== participantID}
              <a
                use:link
                href="/participant/{participant.id}"
                aria-label="Gå til deltaker"
                onclick={() => {
                  trackEvent({
                    action: 'click:participant-scoreboard',
                    label: `participant:${participant.id}`,
                  });
                }}
              >
                <Smiles {participant} />
              </a>
            {:else}
              <Smiles {participant} />
            {/if}
          {/each}
          {#each $maskedParticipants as participant}
            <Smiles {participant} />
          {/each}
        </div>
      </span>
    </div>
  </div>

  {#if participantState}
    {#each unmaskedParticipantsCopy as participant, i}
      {#if participant.id === participantID}
        <div
          class="score-text {participantState}"
          style="grid-column: {i + 1}/{i + 3} "
        >
          {#if participantState === 'correct'}
            Jippi!
          {:else if participantState === 'wrong'}
            Ai, ai, ai
          {:else if participantState === 'empty'}
            Du gjettet ikke
          {/if}
        </div>
      {/if}
    {/each}
  {:else}
    <div class="stats">
      <div class="points-shadow">
        <div class="points"><span>{points}</span> poeng</div>
      </div>
      <Statistics />
    </div>
  {/if}
</div>

<style>
  .scoreboard-wrapper {
    position: relative;
    width: 100%;
    max-width: var(--content-width, 30rem);
    display: grid;
    grid-template-columns: repeat(8, 1fr);
  }
  h3 {
    color: rgb(189, 96, 255);
  }
  .dot-wrapper {
    display: flex;
    justify-content: space-evenly;
    align-items: center;
  }

  .stats {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 2rem;
    grid-column: 1/9;
    margin: -1rem 0 2rem;
    width: 100%;
  }

  .points-shadow {
    filter: drop-shadow(0px 4px 10px var(--purple));
  }

  .points {
    background: var(--purple);
    white-space: nowrap;
    padding: 0.2rem 1rem;
    justify-self: center;
    text-align: center;
    --corner-size: 0.5rem;
    text-decoration: none;
    clip-path: polygon(
      0 0,
      calc(100% - var(--corner-size)) 0,
      100% var(--corner-size),
      100% 100%,
      var(--corner-size) 100%,
      0 calc(100% - var(--corner-size))
    );
    & > span {
      font-weight: 800;
    }
  }

  .score-text {
    --corner-size: 0.5rem;
    text-decoration: none;
    clip-path: polygon(
      0 0,
      calc(100% - var(--corner-size)) 0,
      100% var(--corner-size),
      100% 100%,
      var(--corner-size) 100%,
      0 calc(100% - var(--corner-size))
    );
    position: absolute;
    padding: 0.2rem 1rem;
    transform: translateY(1.3rem);
    justify-self: center;
    white-space: nowrap;
    display: grid;
    place-items: center;
    text-transform: uppercase;
    font-weight: 700;
    font-size: 0.7rem;
  }

  .wrong {
    background: var(--orange);
    color: black;
  }

  .correct {
    background: var(--green);
    color: black;
  }

  .empty {
    background: var(--purple);
    text-transform: unset;
    color: white;
  }

  .scoreboard.correct {
    background: var(--green);
  }

  .scoreboard.wrong {
    background: var(--orange);
  }
  .scoreboard.empty {
    background: var(--purple);
  }

  .scoreboard {
    --corner-size: 0.75rem;
    text-decoration: none;
    background: var(--purple);
    clip-path: polygon(
      0 0,
      calc(100% - var(--corner-size)) 0,
      100% var(--corner-size),
      100% 100%,
      var(--corner-size) 100%,
      0 calc(100% - var(--corner-size))
    );
    text-transform: uppercase;
    font-size: 0.875rem;
    text-align: center;
    line-height: 2.85;
    font-weight: 700;
    padding: 0;
    width: 100%;
    transition: transform 25ms ease-out;
  }

  .scoreboard__shadow-wrapper {
    transition: all 1.4s ease-out;
    grid-column: 1/-1;
    width: 100%;

    background-color: transparent;
    margin: 2rem auto;
    align-items: center;
    filter: drop-shadow(1px 1px 28px var(--purple));
  }

  .scoreboard__shadow-wrapper.correct {
    filter: drop-shadow(1px 1px 28px var(--green));
  }
  .scoreboard__shadow-wrapper.wrong {
    filter: drop-shadow(1px 1px 28px var(--orange));
  }

  .scoreboard__shadow-wrapper.empty {
    filter: drop-shadow(1px 1px 28px var(--purple));
  }

  .scoreboard__content {
    width: 100%;
    height: 6.2rem;
    display: inline-block;

    background: rgb(71, 34, 94);
    color: rgb(132, 63, 174);
    --border-size: 0.125rem;
    --zero-position: calc(0% + var(--border-size));
    --hundred-position: calc(100% - var(--border-size));
    --half-border: calc(var(--border-size) / 2);
    /* Mad scientist calculations to get the correct size of border */
    clip-path: polygon(
      var(--zero-position) var(--zero-position),
      calc(var(--hundred-position) - (var(--corner-size) - var(--half-border)))
        var(--zero-position),
      var(--hundred-position) calc(var(--corner-size) + var(--half-border)),
      var(--hundred-position) var(--hundred-position),
      calc(var(--corner-size) + var(--half-border)) var(--hundred-position),
      var(--zero-position)
        calc(
          var(--hundred-position) - (var(--corner-size) - var(--half-border))
        )
    );
  }
</style>
