<script lang="ts">
  import { onMount } from 'svelte';
  import Router, { location, type RouteLoadedEvent } from 'svelte-spa-router';
  import { initDataSync } from './api/api';
  import { region } from './api/location.svelte';
  import CookieBanner from './lib/components/CookieBanner.svelte';
  import FeedbackLink from './lib/components/Feedback/FeedbackLink.svelte';
  import Footer from './lib/components/Footer.svelte';
  import { consentVersion } from './lib/components/Login/consentVersion.js';
  import Header from './lib/components/Login/Header.svelte';
  import {
    fetchInitialSession,
    loginSessionStore,
  } from './lib/components/Login/loginSessionStore.js';
  import LoaderLogo from './lib/components/Logo/LoaderLogo.svelte';
  import Modal from './lib/components/Modal/Modal.svelte';
  import { trackPageView } from './lib/util/snowplow';
  import ConsentPage from './pages/ConsentPage.svelte';
  import IntroPage from './pages/IntroPage.svelte';
  import LocationPage from './pages/LocationPage.svelte';
  import MainPage from './pages/MainPage.svelte';
  import ParticipantPage from './pages/ParticipantPage.svelte';

  const routes = {
    '/': MainPage,
    '/participant/:id': ParticipantPage,
  };
  let loading = $state(true);

  onMount(async () => {
    try {
      const session = await fetchInitialSession();
      if (session.isLoggedIn) {
        await region.fetchLocation();
      }
    } finally {
      loading = false;
    }
  });

  let mustLogIn = $derived($loginSessionStore?.isLoggedIn === false);
  let mustConsent = $derived(
    $loginSessionStore?.isLoggedIn &&
      $loginSessionStore?.userConformsToRequirements({
        requirements: [consentVersion],
      }) === false &&
      region.location?.optedOut !== true
  );
  let hasSetLocation = $derived(
    region.location?.optedOut || region.location?.communeNumber != null
  );
  let mustSetLocation = $derived(!hasSetLocation || region.justSet);
  let readyToInitGame = $derived(
    !mustLogIn && !mustConsent && !mustSetLocation
  );
  $effect(() => {
    if (readyToInitGame) {
      initDataSync();
    }
  });

  let isParticipantPage = $derived($location.startsWith('/participant/'));
  const onRouteLoaded = async (event: RouteLoadedEvent) => {
    trackPageView(event.detail.location);
  };
</script>

<CookieBanner />
<Header />
<main>
  <div class="bg-grad-red"></div>
  {#if loading}
    <LoaderLogo loading={true} padding={true} />
  {:else if mustLogIn}
    <IntroPage />
  {:else if mustConsent}
    <ConsentPage />
  {:else if mustSetLocation}
    <LocationPage />
  {:else}
    <Router {routes} on:routeLoaded={onRouteLoaded} />
  {/if}
  {#if !isParticipantPage}
    <FeedbackLink />
  {/if}
</main>
{#if !isParticipantPage}
  <Footer />
{/if}

<Modal />

<style>
  main {
    min-height: 50vh;
  }
</style>
