<script lang="ts">
  /* eslint svelte/no-at-html-tags: "off" */
  import { nrkArrowLeft, nrkSpinner } from '@nrk/core-icons';
  import { onMount } from 'svelte';
  import { region } from '../api/location.svelte';
  import LocationInput from '../lib/components/Location/LocationInput.svelte';
  import { regions } from '../lib/components/Location/regions';
  import LoaderLogo from '../lib/components/Logo/LoaderLogo.svelte';
  import RandomBlinkDiamonds from '../lib/components/Logo/RandomBlinkDiamonds.svelte';
  import { trackEvent, trackPageView } from '../lib/util/snowplow';
  import { showModal } from '../lib/util/stores/modalStore';
  export let isModal = false;
  let heading: HTMLHeadingElement;

  onMount(() => {
    if (!isModal) {
      trackPageView('locationpage');
    }
    if (heading != null) {
      heading.focus();
    }
  });
</script>

<div class="location-page">
  <div class="bg"></div>
  {#if isModal}
    <button
      class="back-button"
      title="Lukk"
      onclick={() => showModal.set(undefined)}
    >
      <span class="arrow">
        {@html nrkArrowLeft}
      </span>
    </button>
  {/if}
  <LoaderLogo />
  {#if region.justSet}
    <div class="centrify">
      <RandomBlinkDiamonds />
      <h2>
        Heia {regions.find((r) => r.id === region.location?.communeNumber)
          ?.name}
      </h2>
    </div>
  {:else}
    <!-- svelte-ignore a11y_no_noninteractive_tabindex -->
    <h1 tabindex={-1} bind:this={heading}>Hvilken kommune kommer du fra?</h1>
    <p>
      Fortell oss hvilken kommune du kommer fra og bli med i trekningen av
      Maskorama-boksen. Vinnerens navn og kommune annonseres i sendingen.
    </p>
    <LocationInput isModal={true} />
    <button
      disabled={region.isSubmitting}
      onclick={async () => {
        await region.setLocation({ optedOut: true, communeNumber: null });
        trackEvent({ action: 'location:set', label: 'optOut' });
        showModal.set(undefined);
      }}
      >Spill uten å vinne premier
      {#if region.isSubmitting}
        <div class="spinner">
          {@html nrkSpinner}
        </div>
      {/if}</button
    >
  {/if}
</div>

<style>
  .location-page {
    display: flex;
    padding: 10rem 0;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 2rem;
  }

  .bg {
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: -1;
    background-image: url('../assets/bg-desk.jpg');
    background-position: bottom center;
    background-size: cover;
    background-repeat: no-repeat;
  }

  .back-button {
    position: absolute;
    top: 5rem;
    left: 1.5rem;
    color: black;
    background-color: var(--red);
    border-radius: 9999px;
    width: 2.5rem;
    height: 2.5rem;
    text-decoration: none;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .arrow {
    display: grid;
  }

  .centrify {
    height: 100px;
  }

  h2 {
    text-align: center;
    text-shadow: 0px 0px 13px #ff4777;
    font-size: 48px;
    font-weight: 500;
    color: var(--white);
  }

  p {
    text-align: center;
    color: var(--white);
  }

  button {
    text-transform: uppercase;
    color: var(--white);
    text-decoration: underline;
    font-weight: 600;
    font-size: 1rem;
  }
  h1,
  h2,
  p,
  button {
    max-width: 20rem;
  }

  .spinner {
    animation: spin 1s infinite linear;
    transform-origin: center;
    display: grid;
    place-content: center;
  }

  @keyframes spin {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }

  @media (max-width: 1024px) {
    .bg {
      background-image: url('../assets/bakgrunn_mobil.jpg');
    }
  }
</style>
