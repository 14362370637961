import './lib/util/polyfill';
import '@u-elements/u-datalist';
import { mount } from 'svelte';
import './app.css';
import './lib/util/vhcalculation.js';
import App from './App.svelte';
import { initSentry } from './lib/util/sentry';
import { initializeSnowPlowTracker } from './lib/util/snowplow';
import { initializeVersionCheck } from './lib/util/versionCheck';

const initializeApp = async () => {
  initializeVersionCheck();
  initializeSnowPlowTracker();
  initSentry();

  return mount(App, {
    target: document.getElementById('app')!,
  });
};

const app = initializeApp();

export default app;
