<script lang="ts">
  export let height = 47;
  export let width = 327;

  const mapNumber = (
    x: number,
    oldMin: number,
    oldMax: number,
    newMin: number,
    newMax: number
  ) => {
    return ((x - oldMin) * (newMax - newMin)) / (oldMax - oldMin) + newMin;
  };

  const nums = [
    Math.random(),
    Math.random(),
    Math.random(),
    Math.random(),
    Math.random(),
    Math.random(),
    Math.random(),
  ];

  const colors = ['#F5255B', '#40E0F2'];
  const blinkTimings = [1.2, 1.5, 2.3, 3.1];
  const sizes = [3, 4, 5, 6, 7];
  const maxSize = Math.max(...sizes);
</script>

<svg
  style="height: {height}px"
  {width}
  {height}
  viewBox="-{maxSize} -maxSize {width + maxSize} {height + maxSize}"
  fill="none"
  xmlns="http://www.w3.org/2000/svg"
  aria-hidden="true"
>
  {#each nums as randomNum, i}
    {@const x = mapNumber(randomNum, 0, 1, 0, width)}
    {@const y = mapNumber(Math.random(), 0, 1, 0, height)}
    <rect
      {x}
      {y}
      width={sizes[i % sizes.length]}
      height={sizes[i % sizes.length]}
      transform="rotate(45 {x} {y})"
      fill={colors[i % colors.length]}
    >
      <animate
        attributeName="opacity"
        values="1;0;1"
        dur="{blinkTimings[i % blinkTimings.length]}s"
        repeatCount="indefinite"
      />
    </rect>
  {/each}
</svg>
