<script>
  export let color = 'black';
</script>

<svg
  width="39"
  height="22"
  viewBox="0 0 39 22"
  fill={color}
  aria-hidden="true"
  xmlns="http://www.w3.org/2000/svg"
>
  <path
    fill-rule="evenodd"
    clip-rule="evenodd"
    d="M19.3922 3.37255L17.2843 5.90196L0.421569 0L5.05882 10.1176L0 12.2255L2.95098 17.2843H10.1176L11.3824 19.8137L19.3922 21.5V3.37255ZM19.3924 3.37255L21.5002 5.90196L38.363 0L33.7257 10.1176L38.7845 12.2255L35.8335 17.2843H28.6669L27.4022 19.8137L19.3924 21.5V3.37255ZM23.7915 11L30.804 12.125L29.5665 13.625L21.804 14L23.7915 11ZM7.80396 12.125L14.8165 11L16.804 14L9.04146 13.625L7.80396 12.125Z"
  />
</svg>
