<script lang="ts">
  /* eslint svelte/no-at-html-tags: "off" */
  import { nrkArrowLeft } from '@nrk/core-icons';
  import { onDestroy, onMount } from 'svelte';
  import { link, params } from 'svelte-spa-router';
  import { workData } from '../api/api';
  import ActiveParticipant from '../lib/components/Participant/ActiveParticipant.svelte';
  import ParticipantPageBackground from '../lib/components/Participant/ParticipantPageBackground.svelte';
  import RescuedParticipant from '../lib/components/Participant/RescuedParticipant.svelte';
  import UnMaskedParticipant from '../lib/components/Participant/UnMaskedParticipant.svelte';
  $: participant = $workData.participants.find(
    (p) => p.id === Number($params?.id)
  );

  onMount(() => {
    window.scrollTo(0, 0);
    document.body.style.overflow = 'hidden';
  });

  onDestroy(() => {
    document.body.style.overflow = 'auto';
  });
</script>

<div class="participant-page">
  {#if participant}
    <h1 tabindex="-1" class="sr-only">{participant.maskName}</h1>
    <div class="width-container">
      {#key participant.id}
        <ParticipantPageBackground {participant} />
      {/key}
      {#if participant.rescued}
        <RescuedParticipant {participant} />
      {:else if participant.unmasked || participant.realName != undefined}
        <UnMaskedParticipant {participant} />
      {:else}
        <ActiveParticipant {participant} />
      {/if}
    </div>

    <a
      href={`/`}
      use:link
      class="back-link"
      aria-label="Tilbake til deltakerlisten"
    >
      <span class="arrow">
        {@html nrkArrowLeft}
      </span>
      <img
        aria-hidden="true"
        class=""
        height="48"
        src="assets/maskorama-logo-3d.png"
        alt="Maskorama logo"
      />
      <div class="glow-shadow">
        <div class="pin-fancy-button" aria-hidden={true}>SE ALLE DELTAKERE</div>
      </div>
    </a>
  {/if}
</div>

<style>
  .participant-page {
    --participant-page-height: calc(
      var(--vh, 1vh) * 100 - var(--cookie-banner-height, 0px)
    );
    height: var(--participant-page-height);
    display: grid;
    justify-items: center;
    align-items: end;
    color: white;
    position: relative;
    overflow: hidden;
  }

  .width-container {
    position: relative;
    height: 100%;
    width: 100%;
    max-width: min(100%, 100vh);
    overflow: hidden;
  }

  .back-link {
    position: absolute;
    top: 5rem;
    left: 1.5rem;
    color: black;
    background-color: var(--red);
    border-radius: 9999px;
    width: 2.5rem;
    height: 2.5rem;
    text-decoration: none;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .arrow {
    display: flex;
  }

  .back-link > img,
  .back-link > .glow-shadow {
    display: none;
  }
  .glow-shadow {
    filter: drop-shadow(0px 0px 18px rgb(245, 37, 91));
  }

  @media (min-width: 1024px) {
    .back-link {
      background: transparent;
      display: flex;
      width: auto;
    }
    .back-link > span {
      display: none;
    }
    .back-link > img,
    .back-link > .glow-shadow {
      display: inline;
    }
  }
</style>
