<script lang="ts">
  /* eslint svelte/no-at-html-tags: "off" */
  import { region } from '../../../api/location.svelte';
  import { trackEvent } from '../../util/snowplow';
  import { showModal } from '../../util/stores/modalStore';
  import SendButton from '../Buttons/SendButton.svelte';
  import { regionIdMap, regions, type Region } from './regions';
  let { isModal = false } = $props();

  let locationText = $state(
    region.location?.communeNumber != null
      ? regionIdMap[region.location.communeNumber]?.name || ''
      : ''
  );
  let invalidSubmit = $state(false);
  let sendbutton: SendButton;
  const onLocationChange = (e: KeyboardEvent) => {
    if (invalidSubmit) {
      const selectedRegion = getSelectedRegion();
      if (selectedRegion) {
        invalidSubmit = false;
      }
    }
    if (e.key === 'Enter') {
      submitLocation();
    }
  };

  const createRegionText = (region: Region) => {
    return `${region.name}${
      region.postfix
        ? region.postfix.reduce((aggr, postfix) => aggr + ` - ${postfix}`, '')
        : ''
    }`;
  };

  const getSelectedRegion = () => {
    if (locationText.length > 0) {
      const exactRegion = regions.find(
        (region) =>
          createRegionText(region).toLowerCase() === locationText.toLowerCase()
      );
      if (exactRegion) {
        return exactRegion;
      }

      const filteredRegions = regions.filter((region) => {
        const regionText = createRegionText(region).toLowerCase();
        return regionText.includes(locationText.toLowerCase());
      });
      if (filteredRegions.length === 1) {
        return filteredRegions[0];
      }
      return undefined;
    }
    return undefined;
  };

  const submitLocation = async () => {
    const selectedRegion = getSelectedRegion();
    if (selectedRegion != undefined) {
      const location: Maskorama.Location = {
        optedOut: false,
        communeNumber: selectedRegion.id,
      };
      await region.setLocation(location);
      if (isModal) {
        window.setTimeout(() => showModal.set(undefined), 2_000);
      }
    } else {
      invalidSubmit = true;
    }

    trackEvent({ action: 'location:set', label: 'hasCommune' });
  };
</script>

{#if invalidSubmit}
  <div class="error-text">Du må velge en gyldig kommune!</div>
{/if}
<div class="suggest-wrapper">
  <div class="input-border">
    <div class="input-content">
      <input
        class="location-input"
        aria-label="Hvilken kommune kommer du fra?"
        type="text"
        placeholder="Skriv kommunen din"
        maxlength="100"
        onkeyup={onLocationChange}
        bind:value={locationText}
        oninput={(event: Event & { inputType?: string }) => {
          if (!event.inputType) {
            // Event is triggered by user selecting an option in datalist
            sendbutton.focus();
          }
        }}
        autocomplete="off"
        list="region-list"
      />
      <SendButton
        bind:this={sendbutton}
        onClick={submitLocation}
        sending={region.isSubmitting}
      />
    </div>
  </div>
  <u-datalist id="region-list">
    {#each regions as region}
      {@const regionText = createRegionText(region)}

      <u-option class="region-item">
        {regionText}
      </u-option>
    {/each}
  </u-datalist>
</div>

<style>
  .error-text {
    color: var(--red);
    font-size: 0.875rem;
    font-weight: 700;
    text-align: center;
    margin-top: 0.5rem;
  }
  .suggest-wrapper {
    position: relative;
    width: 98vw;
    max-width: 332px;
  }
  .input-border {
    --corner-size: 0.75rem;
    text-decoration: none;
    background-color: var(--red);
    color: white;
    clip-path: polygon(
      0 0,
      calc(100% - var(--corner-size)) 0,
      100% var(--corner-size),
      100% 100%,
      var(--corner-size) 100%,
      0 calc(100% - var(--corner-size))
    );
    text-transform: uppercase;
    font-size: 0.875rem;
    text-align: center;
    line-height: 2.85;
    font-weight: 700;
    padding: 0;
  }

  .input-content {
    display: flex;
    align-items: center;
    --border-size: 0.125rem;
    padding: 1.5rem;
    background-color: black;
    color: white;
    --zero-position: calc(0% + var(--border-size));
    --hundred-position: calc(100% - var(--border-size));
    --half-border: calc(var(--border-size) / 2);
    /* Mad scientist calculations to get the correct size of border */
    clip-path: polygon(
      var(--zero-position) var(--zero-position),
      calc(var(--hundred-position) - (var(--corner-size) - var(--half-border)))
        var(--zero-position),
      var(--hundred-position) calc(var(--corner-size) + var(--half-border)),
      var(--hundred-position) var(--hundred-position),
      calc(var(--corner-size) + var(--half-border)) var(--hundred-position),
      var(--zero-position)
        calc(
          var(--hundred-position) - (var(--corner-size) - var(--half-border))
        )
    );
  }

  .location-input {
    background-color: transparent;
    border: none;
    border-radius: 0;
    border-bottom: 1px solid var(--white);
    color: var(--white);
    width: 100%;
  }

  .location-input:focus-visible {
    outline: none;
    box-shadow: 0 1px 0 0 var(--white);
  }

  #region-list {
    max-height: 8rem;
    position: absolute;
    left: 0;
    right: 0;
    background-color: var(--black);
    border: 1px solid var(--red);
    overflow-y: scroll;
  }

  .region-item {
    background-color: transparent;
    border: none;
    color: var(--white);
    text-align: left;
    padding: 0.5rem;
    width: 100%;
  }
</style>
