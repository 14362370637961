import { get } from 'svelte/store';
import { loginSessionStore } from '../../lib/components/Login/loginSessionStore';
import { TokenError } from './TokenError';
export async function getTokenData(): Promise<{
  accessToken: string;
  expiresAt: string;
}> {
  const session = get(loginSessionStore);
  if (session == null) {
    throw new Error('Missing session');
  }
  const accessToken = session?.accessToken;
  const expiresAt = new Date(session?.expires).toISOString();
  if ((session.isLoggedIn && session.isExpired) || accessToken == undefined) {
    throw new TokenError('Missing token');
  }

  return { accessToken, expiresAt };
}
