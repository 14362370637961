import { writable } from 'svelte/store';
import { getItem, removeItem } from './storage';

const storageType = 'local';
const key = 'closed-cookie-banner';
const fullkey = `delta-${key}`;
const initialValue = false;
export const createCachedCookieStore = () => {
  const cookieBannerClosedFromStorage = window.localStorage.getItem(fullkey);
  let cookieBannerClosed: boolean;

  if (cookieBannerClosedFromStorage == undefined) {
    cookieBannerClosed = migrateFromOldToNewStore();
  } else {
    cookieBannerClosed = JSON.parse(cookieBannerClosedFromStorage);
  }

  const { subscribe, set: storeSet } = writable(cookieBannerClosed);

  const set = (newValue: boolean) => {
    storeSet(newValue);
    window.localStorage.setItem(fullkey, JSON.stringify(newValue));
  };

  return { subscribe, set };
};

const migrateFromOldToNewStore = () => {
  const previousValue = getItem<boolean>(storageType, key);
  if (previousValue != undefined) {
    removeItem(storageType, key);
    window.localStorage.setItem(fullkey, JSON.stringify(previousValue));
    return previousValue;
  }
  window.localStorage.setItem(fullkey, JSON.stringify(initialValue));
  return initialValue;
};

export const closedCookieBanner = createCachedCookieStore();
