export enum MediaType {
  ListImage = 'list_image',
  MovingStill = 'moving_still',
  MovingStillPoster = 'moving_still_poster',
  ChatBox = 'chat_box',
  DanceText = 'dance_text',
  FavoriteText = 'favourite_text',
  GuessText = 'guess_text',
}

export const isListImage = (x?: Maskorama.MediaSet): x is Maskorama.ListImage =>
  x !== undefined && x.type === MediaType.ListImage;
export const isMovingStill = (
  x?: Maskorama.MediaSet
): x is Maskorama.MovingStill =>
  x !== undefined && x.type === MediaType.MovingStill;
export const isMovingStillPoster = (
  x?: Maskorama.MediaSet
): x is Maskorama.MovingStillPoster =>
  x !== undefined && x.type === MediaType.MovingStillPoster;
export const isChatBox = (x?: Maskorama.MediaSet): x is Maskorama.ChatBox =>
  x !== undefined && x.type === MediaType.ChatBox;
export const isDanceText = (x?: Maskorama.MediaSet): x is Maskorama.DanceText =>
  x !== undefined && x.type === MediaType.DanceText;
export const isFavoriteText = (
  x?: Maskorama.MediaSet
): x is Maskorama.FavoriteText =>
  x !== undefined && x.type === MediaType.FavoriteText;
export const isGuessText = (x?: Maskorama.MediaSet): x is Maskorama.GuessText =>
  x !== undefined && x.type === MediaType.GuessText;
