<script lang="ts">
  import { onMount } from 'svelte';
  let heading: HTMLHeadingElement;

  onMount(() => {
    if (heading != null) {
      heading.focus();
    }
  });
</script>

<div class="content">
  <h2 tabindex="-1" bind:this={heading}>Takk for at du deltok</h2>
  <p>En kjendis er avslørt. Sjekk i oversikten om du har gjettet riktig!</p>
</div>

<style>
  .content {
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 100%;
    max-width: var(--content-width);
    height: 70%;
  }

  p,
  h2 {
    text-align: center;
    color: var(--white);
    margin: 2rem 0 1rem;
  }

  h2 {
    text-transform: uppercase;
    font-weight: 800;
    font-size: 1.25rem;
    line-height: 1.625;
    letter-spacing: 0.1em;
    font-variation-settings: 'wdth' 110;
    text-shadow: 0px 0px 4px RGBA(245, 37, 91, 0.5);
    text-align: center;
    color: var(--red);
  }
</style>
