<script lang="ts">
  import BlinkDiamonds from './BlinkDiamonds.svelte';
  export let loading = false;
  export let text: string | undefined = undefined;
  export let padding = false;
</script>

<div class="container" class:loading class:padding>
  <div class="centrify">
    <BlinkDiamonds />

    {#if loading}
      <div class="maskorama-loader">
        <svg
          height="82"
          width="164"
          viewBox="-12 0 70 32"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <defs>
            <linearGradient id="linear" x1="0%" y1="0%" x2="100%" y2="0%">
              <stop offset="0%" stop-color="#E410AD" />
              <stop offset="100%" stop-color="#40E0F2" />
            </linearGradient>
          </defs>
          <g transform="translate(-6, 0)">
            <path
              fill-rule="evenodd"
              clip-rule="evenodd"
              d="M25.8477 8.78431L0.630431 0L7.56518 15.0588L0 18.1961L4.41302 25.7255H15.1304L17.0216 29.4902L28.9998 32L40.9784 29.4902L42.8697 25.7255H53.587L58 18.1961L50.4348 15.0588L57.3696 1.32593e-05L32.1523 8.78433L29.0028 5.02367L25.8477 8.78431ZM36.636 16.1685L47.1228 17.8429L45.2722 20.0754L33.6638 20.6336L36.636 16.1685ZM12.7276 17.8429L23.2144 16.1685L26.1866 20.6336L14.5782 20.0754L12.7276 17.8429Z"
              fill="transparent"
              stroke="url(#linear)"
              stroke-width="0.75"
              pathLength="100"
            />
          </g>
        </svg>
      </div>
    {:else}
      <!-- svelte-ignore a11y_missing_attribute -->
      <img
        height="82"
        width="164"
        src="assets/logo.webp"
        title="Maskorama logo"
      />
    {/if}
  </div>
  {#if loading && text}
    <p>{text}</p>
  {/if}
</div>

<style>
  .container {
    margin: 0 auto;
    width: 327px;
    height: 82px;
  }

  .container.loading {
    min-height: 8.75rem;
  }

  .centrify {
    max-height: 102px;
  }

  .padding {
    padding-top: 6rem;
  }

  .container.loading {
    filter: drop-shadow(0px 0px 6px var(--red));
  }

  @keyframes move-lines {
    0% {
      stroke-dashoffset: 0;
    }
    100% {
      stroke-dashoffset: 60;
    }
  }

  .maskorama-loader {
    grid-area: center;
    /* padding-top: 0.25rem; */
    margin: 0 auto;
    height: 82px;
    animation: move-lines 20s infinite linear;
    stroke-dasharray: 20 10;
    filter: drop-shadow(0 0 5px var(--cyan));
  }

  p {
    margin: 1rem auto;
    text-align: center;
  }
</style>
