<script lang="ts">
  /* eslint svelte/no-at-html-tags: "off" */
  import { nrkStarActive } from '@nrk/core-icons';
  import { onMount } from 'svelte';
  import MaskIcon from '../../../assets/MaskIcon.svelte';

  let heading: HTMLHeadingElement;
  onMount(() => {
    if (heading != null) {
      heading.focus();
    }
  });
</script>

<div class="content">
  <h2 tabindex="-1" bind:this={heading}>Sånn virker det</h2>

  <div class="icon">
    {@html nrkStarActive}
  </div>

  <p>
    I hver episode kan du velge dine favoritter. Deltakeren med færrest
    favorittmarkeringer må kaste masken. For hver episode kan du velge nye
    favoritter.
  </p>

  <div>
    <MaskIcon color="#f5255b" />
  </div>

  <p>
    Gjett hvem som skjuler seg bak masken. Skriv inn ditt forslag i tekstfeltet,
    følg med på sendingen og se om du vinner! Du kan endre forslag underveis.
  </p>
</div>

<style>
  .content {
    display: grid;
    place-content: center;
    max-width: 30rem;
    height: 70%;
  }
  p,
  div,
  h2 {
    text-align: center;
  }

  h2 {
    text-transform: uppercase;
  }

  p {
    color: white;
  }

  div {
    color: #f5255b;
    margin: 2rem 0 1rem;
  }
</style>
