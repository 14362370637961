import * as Sentry from '@sentry/svelte';

export const browserSupportsWebgl = () => {
  const canvas = document.createElement('canvas');
  try {
    // Get WebGLRenderingContext from canvas element.
    const gl = canvas.getContext('webgl');

    return gl instanceof WebGLRenderingContext && gl.isContextLost() === false;
  } catch (e) {
    Sentry.captureException(e);
    return false;
  }
};
