<script lang="ts">
  import { postFeedback, updateFeedback } from '../../../api/feedback.js';
  import { showModal } from '../../util/stores/modalStore.js';
  import EmojiFeedback from './EmojiFeedback.svelte';

  type Steps = 'SendFeedback' | 'Done';

  export let step: Steps = 'SendFeedback';
  let emojivalue = -1;
  let comment = '';
  let isSending = false;
  let category = '';
  let heading: HTMLHeadingElement;

  const handleNext = async () => {
    if (step === 'SendFeedback') {
      isSending = true;
      await postFeedback({ votesFeedback: emojivalue, category });
      if (comment.length) {
        await updateFeedback(comment);
      }
      emojivalue = -1;
      comment = '';
      category = '';
      step = 'Done';
      isSending = false;
    }
  };

  $: if (heading != null) {
    heading.focus();
  }

  $: hasChangedValues = emojivalue !== -1 || comment !== '';
</script>

<div data-nosnippet class="content">
  {#if step === 'SendFeedback'}
    <EmojiFeedback bind:group={emojivalue} bind:comment />
    <div class="action-wrapper">
      <button
        class="close-button"
        on:click={() => {
          showModal.set(undefined);
        }}>Avbryt</button
      >
      <button
        class="send-button"
        on:click={handleNext}
        disabled={isSending || !hasChangedValues}
        aria-label="Send inn tilbakemelding"
      >
        <svg
          aria-hidden="true"
          class="send-button__icon"
          viewBox="0 0 19 18"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M16.6661 10.7445C18.548 9.87524 18.548 7.20043 16.6661 6.33122L3.44982 0.22687C1.83918 -0.517053 0 0.659344 0 2.43349V5.70047C0 6.52698 0.622041 7.22103 1.44361 7.31121L9.31896 8.17566C9.54734 8.19581 9.72249 8.3871 9.72249 8.61637C9.72249 8.84564 9.54734 9.03693 9.31896 9.05708L1.44361 9.92152C0.62204 10.0117 0 10.7058 0 11.5323V14.6422C0 16.4163 1.83918 17.5927 3.44982 16.8488L16.6661 10.7445Z"
          />
        </svg>
      </button>
    </div>
  {:else}
    <h2 tabindex="-1" bind:this={heading}>Takk for din tilbakemelding!</h2>
  {/if}
</div>

<style>
  .content {
    display: grid;
    place-content: center;
    height: 70%;
    margin: 4rem auto 0;
    color: white;
  }

  .action-wrapper {
    display: flex;
    justify-content: space-between;
    margin-top: 0.5rem;
  }

  h2 {
    color: var(--white);
  }
  button {
    margin: 0 2rem;
  }

  .send-button {
    transition: all 0.2s;
    color: var(--red);
  }

  .send-button:not(:disabled) {
    filter: drop-shadow(0px 0px 9px rgba(245, 37, 92, 0.82));
    animation: pulse 1.2s infinite ease-in;
  }

  @keyframes pulse {
    0% {
      transform: scale(1);
    }
    20% {
      transform: scale(1.1);
    }
    100% {
      transform: scale(1);
    }
  }

  .send-button svg {
    fill: currentColor;
    width: 2.5rem;
    height: 2.5rem;
  }

  .send-button:disabled {
    color: grey;
  }
</style>
