<script lang="ts">
  import { region } from '../../../api/location.svelte';
  import { trackEvent } from '../../util/snowplow';
  import { showModal } from '../../util/stores/modalStore';
  import { consentVersion } from '../Login/consentVersion';
  import { loginSessionStore } from '../Login/loginSessionStore';
  import { regionIdMap } from './regions';

  let locationSet = $derived(
    region.location?.optedOut === false &&
      region.location?.communeNumber != null
  );
  let mustConsent = $derived(
    $loginSessionStore?.isLoggedIn &&
      $loginSessionStore?.userConformsToRequirements({
        requirements: [consentVersion],
      }) === false
  );
</script>

<p>
  {`${locationSet ? 'Du representerer' : 'Du er ikke med i trekningen av Maskoramaboksen'}`}

  <button
    onclick={() => {
      if (!locationSet && mustConsent) {
        showModal.set('consent');
        trackEvent({
          action: 'location:toggle-view',
          label: 'must-consent',
        });
        return;
      }
      showModal.set('location');
      trackEvent({
        action: 'location:toggle-view',
        label: locationSet ? 'has-commune' : 'no-commune',
      });
    }}
    title={!locationSet && mustConsent
      ? 'Trykk for å godta vilkår og bli med i trekningen'
      : locationSet
        ? 'Trykk for å endre kommune'
        : 'Trykk for å registrere kommune og bli med i trekningen'}
  >
    {locationSet && region.location?.communeNumber != null
      ? regionIdMap[region.location.communeNumber]?.name
      : 'Bli med!'}
  </button>
</p>

<style>
  p {
    margin: 6rem auto 2rem;
    text-align: center;
    font-size: 1.25rem;
    line-height: 1.25rem;
  }

  button {
    color: var(--red);
    text-decoration: underline;
    padding: 0;
    filter: drop-shadow(2px 2px 2px var(--black));
  }
</style>
