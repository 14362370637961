<script lang="ts">
  import BlinkDiamonds from './Logo/BlinkDiamonds.svelte';
  export let heading: HTMLHeadingElement | undefined = undefined;
</script>

<div class="container">
  <BlinkDiamonds height={68} />
  <h1 tabindex="-1" bind:this={heading}>
    <slot></slot>
  </h1>
</div>

<style>
  .container {
    display: grid;
    grid-template-areas: 'center';
    place-items: center;
  }

  .container > h1 {
    grid-area: center;
    width: 100%;
  }

  .container > :global(*) {
    grid-area: center;
  }

  h1 {
    max-width: 16rem;
    height: fit-content;
  }
</style>
