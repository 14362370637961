import { getItem, setItem } from '../../util/storage';

const createRescuedState = () => {
  const hide = getItem<boolean>('local', 'hideRescuedParticipant');
  let hiderescuedparticipant = $state<boolean>(hide ?? true);

  return {
    get hidden() {
      return hiderescuedparticipant;
    },
    revealRescuedParticipant: () => {
      hiderescuedparticipant = false;
      setItem('local', 'hideRescuedParticipant', hiderescuedparticipant);
    },
  };
};
export const rescuedparticipant = createRescuedState();
