import { distinctUntilChanged, of, shareReplay, switchMap, timer } from 'rxjs';
import { getParticipants, workData } from '../../../api/api';
import { fromSvelteStore } from '../../util/rxjs.svelte';
import { showModal } from '../../util/stores/modalStore';
import { statistics } from '../../../api/statistics.svelte';

// Observable to listen to the endtime
export const roundPaused = fromSvelteStore(workData)
  .pipe(
    switchMap((data) => {
      const endtime = data.round?.endTime;

      if (!endtime) {
        return of('not_paused'); // Emit 'not_paused' if no endtime is set
      }

      const timeLeft = endtime - Date.now() / 1000;
      if (timeLeft <= 0) {
        return of('paused'); // Emit 'paused' if time has already passed
      }

      return of('not_paused').pipe(
        switchMap(() => {
          return timer(timeLeft * 1000).pipe(
            switchMap(() => {
              return of('paused');
            }) // Emit 'paused' when the endtime is reached
          );
        })
      );
    })
  )
  .pipe(
    shareReplay({ bufferSize: 1, refCount: true }),
    distinctUntilChanged() // Ensure distinct state changes
  );

let previousStatus = 'initial';
// trigger locked modal when round is paused
// and thanks modal when round transitioning from paused to unpaused.
// while thanks modal is shown, we refetch participants and update percentage
roundPaused.subscribe((status) => {
  if (status === 'paused') {
    showModal.set('locked');
  } else if (status === 'not_paused' && previousStatus === 'paused') {
    showModal.set('thanks');
    getParticipants();
    statistics.fetchPercentage();
  }
  previousStatus = status;
});
