import { getSuggest } from './suggest';

export const checkSpelling = async (guess: string, participantId: number) => {
  if (guess.length <= 0) {
    return;
  }

  const result = await getSuggest(guess, participantId);
  return result;
};
