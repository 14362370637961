export type Region = {
  id: number;
  name: string;
  parentRegion: string;
  postfix?: string[];
};

// GenerationTimestamp = 2024-09-26T08:05:19.786Z
export const regions: Array<Region> = [
  { parentRegion: 'Nordland', id: 1820, name: 'Alstahaug', postfix: [] },
  { parentRegion: 'Finnmark', id: 5601, name: 'Alta', postfix: [] },
  { parentRegion: 'Innlandet', id: 3428, name: 'Alvdal', postfix: [] },
  { parentRegion: 'Vestland', id: 4631, name: 'Alver', postfix: [] },
  { parentRegion: 'Nordland', id: 1871, name: 'Andøy', postfix: [] },
  { parentRegion: 'Østfold', id: 3124, name: 'Aremark', postfix: [] },
  { parentRegion: 'Agder', id: 4203, name: 'Arendal', postfix: [] },
  { parentRegion: 'Akershus', id: 3203, name: 'Asker', postfix: [] },
  { parentRegion: 'Vestland', id: 4645, name: 'Askvoll', postfix: [] },
  { parentRegion: 'Vestland', id: 4627, name: 'Askøy', postfix: [] },
  { parentRegion: 'Møre og Romsdal', id: 1547, name: 'Aukra', postfix: [] },
  { parentRegion: 'Møre og Romsdal', id: 1576, name: 'Aure', postfix: [] },
  { parentRegion: 'Vestland', id: 4641, name: 'Aurland', postfix: [] },
  { parentRegion: 'Akershus', id: 3226, name: 'Aurskog-Høland', postfix: [] },
  { parentRegion: 'Vestland', id: 4625, name: 'Austevoll', postfix: [] },
  { parentRegion: 'Vestland', id: 4632, name: 'Austrheim', postfix: [] },
  { parentRegion: 'Møre og Romsdal', id: 1554, name: 'Averøy', postfix: [] },
  { parentRegion: 'Troms', id: 5532, name: 'Balsfjord', postfix: [] },
  { parentRegion: 'Telemark', id: 4012, name: 'Bamble', postfix: [] },
  { parentRegion: 'Troms', id: 5520, name: 'Bardu', postfix: [] },
  { parentRegion: 'Nordland', id: 1839, name: 'Beiarn', postfix: [] },
  { parentRegion: 'Vestland', id: 4601, name: 'Bergen', postfix: [] },
  { parentRegion: 'Finnmark', id: 5630, name: 'Berlevåg', postfix: [] },
  { parentRegion: 'Nordland', id: 1811, name: 'Bindal', postfix: [] },
  { parentRegion: 'Agder', id: 4216, name: 'Birkenes', postfix: [] },
  { parentRegion: 'Rogaland', id: 1114, name: 'Bjerkreim', postfix: [] },
  { parentRegion: 'Vestland', id: 4624, name: 'Bjørnafjorden', postfix: [] },
  { parentRegion: 'Nordland', id: 1804, name: 'Bodø', postfix: [] },
  { parentRegion: 'Rogaland', id: 1145, name: 'Bokn', postfix: [] },
  { parentRegion: 'Vestland', id: 4648, name: 'Bremanger', postfix: [] },
  { parentRegion: 'Nordland', id: 1813, name: 'Brønnøy', postfix: [] },
  { parentRegion: 'Agder', id: 4220, name: 'Bygland', postfix: [] },
  { parentRegion: 'Agder', id: 4222, name: 'Bykle', postfix: [] },
  { parentRegion: 'Akershus', id: 3201, name: 'Bærum', postfix: [] },
  { parentRegion: 'Nordland', id: 1867, name: 'Bø', postfix: [] },
  { parentRegion: 'Vestland', id: 4613, name: 'Bømlo', postfix: [] },
  { parentRegion: 'Finnmark', id: 5632, name: 'Båtsfjord', postfix: [] },
  { parentRegion: 'Innlandet', id: 3431, name: 'Dovre', postfix: [] },
  { parentRegion: 'Buskerud', id: 3301, name: 'Drammen', postfix: [] },
  { parentRegion: 'Telemark', id: 4016, name: 'Drangedal', postfix: [] },
  { parentRegion: 'Troms', id: 5528, name: 'Dyrøy', postfix: [] },
  { parentRegion: 'Nordland', id: 1827, name: 'Dønna', postfix: [] },
  { parentRegion: 'Vestland', id: 4619, name: 'Eidfjord', postfix: [] },
  { parentRegion: 'Innlandet', id: 3416, name: 'Eidskog', postfix: [] },
  { parentRegion: 'Akershus', id: 3240, name: 'Eidsvoll', postfix: [] },
  { parentRegion: 'Rogaland', id: 1101, name: 'Eigersund', postfix: [] },
  { parentRegion: 'Innlandet', id: 3420, name: 'Elverum', postfix: [] },
  { parentRegion: 'Akershus', id: 3220, name: 'Enebakk', postfix: [] },
  { parentRegion: 'Innlandet', id: 3425, name: 'Engerdal', postfix: [] },
  { parentRegion: 'Vestland', id: 4611, name: 'Etne', postfix: [] },
  { parentRegion: 'Innlandet', id: 3450, name: 'Etnedal', postfix: [] },
  { parentRegion: 'Nordland', id: 1853, name: 'Evenes', postfix: ['Evenášši'] },
  { parentRegion: 'Agder', id: 4219, name: 'Evje og Hornnes', postfix: [] },
  { parentRegion: 'Agder', id: 4206, name: 'Farsund', postfix: [] },
  { parentRegion: 'Nordland', id: 1841, name: 'Fauske', postfix: ['Fuossko'] },
  { parentRegion: 'Vestland', id: 4633, name: 'Fedje', postfix: [] },
  { parentRegion: 'Vestland', id: 4615, name: 'Fitjar', postfix: [] },
  { parentRegion: 'Vestland', id: 4646, name: 'Fjaler', postfix: [] },
  { parentRegion: 'Møre og Romsdal', id: 1578, name: 'Fjord', postfix: [] },
  { parentRegion: 'Nordland', id: 1859, name: 'Flakstad', postfix: [] },
  { parentRegion: 'Trøndelag', id: 5049, name: 'Flatanger', postfix: [] },
  { parentRegion: 'Agder', id: 4207, name: 'Flekkefjord', postfix: [] },
  { parentRegion: 'Buskerud', id: 3334, name: 'Flesberg', postfix: [] },
  { parentRegion: 'Buskerud', id: 3320, name: 'Flå', postfix: [] },
  { parentRegion: 'Innlandet', id: 3429, name: 'Folldal', postfix: [] },
  { parentRegion: 'Østfold', id: 3107, name: 'Fredrikstad', postfix: [] },
  { parentRegion: 'Akershus', id: 3214, name: 'Frogn', postfix: [] },
  { parentRegion: 'Agder', id: 4214, name: 'Froland', postfix: [] },
  { parentRegion: 'Trøndelag', id: 5036, name: 'Frosta', postfix: [] },
  { parentRegion: 'Trøndelag', id: 5014, name: 'Frøya', postfix: [] },
  { parentRegion: 'Telemark', id: 4032, name: 'Fyresdal', postfix: [] },
  { parentRegion: 'Vestfold', id: 3911, name: 'Færder', postfix: [] },
  { parentRegion: 'Finnmark', id: 5626, name: 'Gamvik', postfix: [] },
  { parentRegion: 'Innlandet', id: 3441, name: 'Gausdal', postfix: [] },
  { parentRegion: 'Nordland', id: 1838, name: 'Gildeskål', postfix: [] },
  { parentRegion: 'Møre og Romsdal', id: 1532, name: 'Giske', postfix: [] },
  { parentRegion: 'Møre og Romsdal', id: 1557, name: 'Gjemnes', postfix: [] },
  { parentRegion: 'Akershus', id: 3230, name: 'Gjerdrum', postfix: [] },
  { parentRegion: 'Agder', id: 4211, name: 'Gjerstad', postfix: [] },
  { parentRegion: 'Rogaland', id: 1122, name: 'Gjesdal', postfix: [] },
  { parentRegion: 'Innlandet', id: 3407, name: 'Gjøvik', postfix: [] },
  { parentRegion: 'Vestland', id: 4650, name: 'Gloppen', postfix: [] },
  { parentRegion: 'Buskerud', id: 3324, name: 'Gol', postfix: [] },
  { parentRegion: 'Innlandet', id: 3446, name: 'Gran', postfix: [] },
  { parentRegion: 'Nordland', id: 1825, name: 'Grane', postfix: [] },
  { parentRegion: 'Troms', id: 5516, name: 'Gratangen', postfix: [] },
  { parentRegion: 'Agder', id: 4202, name: 'Grimstad', postfix: [] },
  { parentRegion: 'Trøndelag', id: 5045, name: 'Grong', postfix: [] },
  { parentRegion: 'Innlandet', id: 3417, name: 'Grue', postfix: [] },
  { parentRegion: 'Vestland', id: 4635, name: 'Gulen', postfix: [] },
  { parentRegion: 'Nordland', id: 1866, name: 'Hadsel', postfix: [] },
  { parentRegion: 'Østfold', id: 3101, name: 'Halden', postfix: [] },
  { parentRegion: 'Innlandet', id: 3403, name: 'Hamar', postfix: [] },
  { parentRegion: 'Nordland', id: 1875, name: 'Hamarøy', postfix: ['Hábmer'] },
  {
    parentRegion: 'Finnmark',
    id: 5603,
    name: 'Hammerfest',
    postfix: ['Hámmerfeasta'],
  },
  { parentRegion: 'Møre og Romsdal', id: 1580, name: 'Haram', postfix: [] },
  { parentRegion: 'Møre og Romsdal', id: 1517, name: 'Hareid', postfix: [] },
  { parentRegion: 'Troms', id: 5503, name: 'Harstad', postfix: ['Hárstták'] },
  { parentRegion: 'Finnmark', id: 5616, name: 'Hasvik', postfix: [] },
  {
    parentRegion: 'Nordland',
    id: 1826,
    name: 'Hattfjelldal',
    postfix: ['Aarborte'],
  },
  { parentRegion: 'Rogaland', id: 1106, name: 'Haugesund', postfix: [] },
  { parentRegion: 'Trøndelag', id: 5055, name: 'Heim', postfix: [] },
  { parentRegion: 'Nordland', id: 1832, name: 'Hemnes', postfix: [] },
  { parentRegion: 'Buskerud', id: 3326, name: 'Hemsedal', postfix: [] },
  {
    parentRegion: 'Møre og Romsdal',
    id: 1515,
    name: 'Herøy',
    postfix: ['Møre og Romsdal'],
  },
  { parentRegion: 'Nordland', id: 1818, name: 'Herøy', postfix: ['Nordland'] },
  { parentRegion: 'Trøndelag', id: 5056, name: 'Hitra', postfix: [] },
  { parentRegion: 'Telemark', id: 4024, name: 'Hjartdal', postfix: [] },
  { parentRegion: 'Rogaland', id: 1133, name: 'Hjelmeland', postfix: [] },
  { parentRegion: 'Buskerud', id: 3330, name: 'Hol', postfix: [] },
  { parentRegion: 'Buskerud', id: 3310, name: 'Hole', postfix: [] },
  { parentRegion: 'Vestfold', id: 3903, name: 'Holmestrand', postfix: [] },
  { parentRegion: 'Trøndelag', id: 5026, name: 'Holtålen', postfix: [] },
  { parentRegion: 'Vestfold', id: 3901, name: 'Horten', postfix: [] },
  { parentRegion: 'Akershus', id: 3242, name: 'Hurdal', postfix: [] },
  {
    parentRegion: 'Møre og Romsdal',
    id: 1579,
    name: 'Hustadvika',
    postfix: [],
  },
  { parentRegion: 'Østfold', id: 3110, name: 'Hvaler', postfix: [] },
  { parentRegion: 'Vestland', id: 4637, name: 'Hyllestad', postfix: [] },
  { parentRegion: 'Agder', id: 4226, name: 'Hægebostad', postfix: [] },
  { parentRegion: 'Vestland', id: 4638, name: 'Høyanger', postfix: [] },
  { parentRegion: 'Trøndelag', id: 5046, name: 'Høylandet', postfix: [] },
  { parentRegion: 'Rogaland', id: 1119, name: 'Hå', postfix: [] },
  { parentRegion: 'Troms', id: 5514, name: 'Ibestad', postfix: [] },
  { parentRegion: 'Trøndelag', id: 5053, name: 'Inderøy', postfix: [] },
  { parentRegion: 'Trøndelag', id: 5054, name: 'Indre Fosen', postfix: [] },
  { parentRegion: 'Østfold', id: 3118, name: 'Indre Østfold', postfix: [] },
  { parentRegion: 'Agder', id: 4218, name: 'Iveland', postfix: [] },
  { parentRegion: 'Jan Mayen', id: 2211, name: 'Jan Mayen' },
  { parentRegion: 'Akershus', id: 3236, name: 'Jevnaker', postfix: [] },
  {
    parentRegion: 'Finnmark',
    id: 5610,
    name: 'Karasjok',
    postfix: ['Kárášjohka'],
  },
  { parentRegion: 'Troms', id: 5534, name: 'Karlsøy', postfix: [] },
  { parentRegion: 'Rogaland', id: 1149, name: 'Karmøy', postfix: [] },
  {
    parentRegion: 'Finnmark',
    id: 5612,
    name: 'Kautokeino',
    postfix: ['Guovdageaidnu'],
  },
  { parentRegion: 'Vestland', id: 4602, name: 'Kinn', postfix: [] },
  { parentRegion: 'Rogaland', id: 1120, name: 'Klepp', postfix: [] },
  { parentRegion: 'Buskerud', id: 3303, name: 'Kongsberg', postfix: [] },
  { parentRegion: 'Innlandet', id: 3401, name: 'Kongsvinger', postfix: [] },
  { parentRegion: 'Telemark', id: 4014, name: 'Kragerø', postfix: [] },
  { parentRegion: 'Agder', id: 4204, name: 'Kristiansand', postfix: [] },
  {
    parentRegion: 'Møre og Romsdal',
    id: 1505,
    name: 'Kristiansund',
    postfix: [],
  },
  { parentRegion: 'Buskerud', id: 3318, name: 'Krødsherad', postfix: [] },
  { parentRegion: 'Vestland', id: 4622, name: 'Kvam', postfix: [] },
  { parentRegion: 'Agder', id: 4227, name: 'Kvinesdal', postfix: [] },
  { parentRegion: 'Vestland', id: 4617, name: 'Kvinnherad', postfix: [] },
  { parentRegion: 'Telemark', id: 4028, name: 'Kviteseid', postfix: [] },
  { parentRegion: 'Rogaland', id: 1144, name: 'Kvitsøy', postfix: [] },
  { parentRegion: 'Troms', id: 5510, name: 'Kvæfjord', postfix: [] },
  { parentRegion: 'Troms', id: 5546, name: 'Kvænangen', postfix: [] },
  {
    parentRegion: 'Troms',
    id: 5540,
    name: 'Kåfjord',
    postfix: ['Gáivuotna', 'Kaivuono'],
  },
  { parentRegion: 'Vestfold', id: 3909, name: 'Larvik', postfix: [] },
  { parentRegion: 'Troms', id: 5518, name: 'Lavangen', postfix: ['Loabák'] },
  { parentRegion: 'Finnmark', id: 5624, name: 'Lebesby', postfix: [] },
  { parentRegion: 'Nordland', id: 1822, name: 'Leirfjord', postfix: [] },
  { parentRegion: 'Trøndelag', id: 5052, name: 'Leka', postfix: [] },
  { parentRegion: 'Innlandet', id: 3432, name: 'Lesja', postfix: [] },
  { parentRegion: 'Trøndelag', id: 5037, name: 'Levanger', postfix: [] },
  { parentRegion: 'Buskerud', id: 3312, name: 'Lier', postfix: [] },
  { parentRegion: 'Trøndelag', id: 5042, name: 'Lierne', postfix: [] },
  { parentRegion: 'Innlandet', id: 3405, name: 'Lillehammer', postfix: [] },
  { parentRegion: 'Agder', id: 4215, name: 'Lillesand', postfix: [] },
  { parentRegion: 'Akershus', id: 3205, name: 'Lillestrøm', postfix: [] },
  { parentRegion: 'Agder', id: 4205, name: 'Lindesnes', postfix: [] },
  { parentRegion: 'Innlandet', id: 3434, name: 'Lom', postfix: [] },
  { parentRegion: 'Finnmark', id: 5614, name: 'Loppa', postfix: [] },
  { parentRegion: 'Rogaland', id: 1112, name: 'Lund', postfix: [] },
  { parentRegion: 'Akershus', id: 3234, name: 'Lunner', postfix: [] },
  { parentRegion: 'Nordland', id: 1834, name: 'Lurøy', postfix: [] },
  { parentRegion: 'Vestland', id: 4644, name: 'Luster', postfix: [] },
  { parentRegion: 'Agder', id: 4225, name: 'Lyngdal', postfix: [] },
  { parentRegion: 'Troms', id: 5536, name: 'Lyngen', postfix: [] },
  { parentRegion: 'Vestland', id: 4642, name: 'Lærdal', postfix: [] },
  { parentRegion: 'Nordland', id: 1851, name: 'Lødingen', postfix: [] },
  { parentRegion: 'Akershus', id: 3222, name: 'Lørenskog', postfix: [] },
  { parentRegion: 'Innlandet', id: 3412, name: 'Løten', postfix: [] },
  { parentRegion: 'Trøndelag', id: 5031, name: 'Malvik', postfix: [] },
  { parentRegion: 'Østfold', id: 3122, name: 'Marker', postfix: [] },
  { parentRegion: 'Vestland', id: 4634, name: 'Masfjorden', postfix: [] },
  { parentRegion: 'Trøndelag', id: 5028, name: 'Melhus', postfix: [] },
  { parentRegion: 'Nordland', id: 1837, name: 'Meløy', postfix: [] },
  { parentRegion: 'Trøndelag', id: 5034, name: 'Meråker', postfix: [] },
  { parentRegion: 'Telemark', id: 4020, name: 'Midt-Telemark', postfix: [] },
  { parentRegion: 'Trøndelag', id: 5027, name: 'Midtre Gauldal', postfix: [] },
  { parentRegion: 'Vestland', id: 4629, name: 'Modalen', postfix: [] },
  { parentRegion: 'Buskerud', id: 3316, name: 'Modum', postfix: [] },
  { parentRegion: 'Møre og Romsdal', id: 1506, name: 'Molde', postfix: [] },
  { parentRegion: 'Nordland', id: 1874, name: 'Moskenes', postfix: [] },
  { parentRegion: 'Østfold', id: 3103, name: 'Moss', postfix: [] },
  { parentRegion: 'Troms', id: 5524, name: 'Målselv', postfix: [] },
  { parentRegion: 'Finnmark', id: 5618, name: 'Måsøy', postfix: [] },
  {
    parentRegion: 'Trøndelag',
    id: 5007,
    name: 'Namsos',
    postfix: ['Nåavmesjenjaelmie'],
  },
  { parentRegion: 'Trøndelag', id: 5044, name: 'Namsskogan', postfix: [] },
  { parentRegion: 'Akershus', id: 3238, name: 'Nannestad', postfix: [] },
  { parentRegion: 'Nordland', id: 1806, name: 'Narvik', postfix: [] },
  { parentRegion: 'Akershus', id: 3228, name: 'Nes', postfix: [] },
  { parentRegion: 'Buskerud', id: 3322, name: 'Nesbyen', postfix: [] },
  { parentRegion: 'Nordland', id: 1828, name: 'Nesna', postfix: [] },
  { parentRegion: 'Akershus', id: 3212, name: 'Nesodden', postfix: [] },
  { parentRegion: 'Finnmark', id: 5636, name: 'Nesseby', postfix: ['Unjárga'] },
  { parentRegion: 'Telemark', id: 4030, name: 'Nissedal', postfix: [] },
  { parentRegion: 'Akershus', id: 3232, name: 'Nittedal', postfix: [] },
  { parentRegion: 'Telemark', id: 4018, name: 'Nome', postfix: [] },
  { parentRegion: 'Innlandet', id: 3451, name: 'Nord-Aurdal', postfix: [] },
  { parentRegion: 'Innlandet', id: 3436, name: 'Nord-Fron', postfix: [] },
  { parentRegion: 'Innlandet', id: 3414, name: 'Nord-Odal', postfix: [] },
  { parentRegion: 'Finnmark', id: 5620, name: 'Nordkapp', postfix: [] },
  { parentRegion: 'Akershus', id: 3207, name: 'Nordre Follo', postfix: [] },
  { parentRegion: 'Innlandet', id: 3448, name: 'Nordre Land', postfix: [] },
  {
    parentRegion: 'Troms',
    id: 5544,
    name: 'Nordreisa',
    postfix: ['Ráisa', 'Raisi'],
  },
  { parentRegion: 'Buskerud', id: 3338, name: 'Nore og Uvdal', postfix: [] },
  { parentRegion: 'Telemark', id: 4005, name: 'Notodden', postfix: [] },
  { parentRegion: 'Trøndelag', id: 5060, name: 'Nærøysund', postfix: [] },
  { parentRegion: 'Trøndelag', id: 5021, name: 'Oppdal', postfix: [] },
  { parentRegion: 'Trøndelag', id: 5059, name: 'Orkland', postfix: [] },
  { parentRegion: 'Innlandet', id: 3430, name: 'Os', postfix: [] },
  { parentRegion: 'Trøndelag', id: 5020, name: 'Osen', postfix: [] },
  { parentRegion: 'Oslo', id: 301, name: 'Oslo', postfix: [] },
  { parentRegion: 'Vestland', id: 4630, name: 'Osterøy', postfix: [] },
  { parentRegion: 'Trøndelag', id: 5047, name: 'Overhalla', postfix: [] },
  {
    parentRegion: 'Finnmark',
    id: 5622,
    name: 'Porsanger',
    postfix: ['Porsáŋgu', 'Porsanki'],
  },
  { parentRegion: 'Telemark', id: 4001, name: 'Porsgrunn', postfix: [] },
  { parentRegion: 'Østfold', id: 3120, name: 'Rakkestad', postfix: [] },
  { parentRegion: 'Nordland', id: 1833, name: 'Rana', postfix: [] },
  { parentRegion: 'Rogaland', id: 1127, name: 'Randaberg', postfix: [] },
  { parentRegion: 'Møre og Romsdal', id: 1539, name: 'Rauma', postfix: [] },
  { parentRegion: 'Innlandet', id: 3424, name: 'Rendalen', postfix: [] },
  { parentRegion: 'Trøndelag', id: 5022, name: 'Rennebu', postfix: [] },
  { parentRegion: 'Trøndelag', id: 5061, name: 'Rindal', postfix: [] },
  { parentRegion: 'Innlandet', id: 3439, name: 'Ringebu', postfix: [] },
  { parentRegion: 'Buskerud', id: 3305, name: 'Ringerike', postfix: [] },
  { parentRegion: 'Innlandet', id: 3411, name: 'Ringsaker', postfix: [] },
  { parentRegion: 'Agder', id: 4201, name: 'Risør', postfix: [] },
  { parentRegion: 'Buskerud', id: 3336, name: 'Rollag', postfix: [] },
  { parentRegion: 'Akershus', id: 3224, name: 'Rælingen', postfix: [] },
  { parentRegion: 'Nordland', id: 1836, name: 'Rødøy', postfix: [] },
  { parentRegion: 'Trøndelag', id: 5025, name: 'Røros', postfix: ['Rosse'] },
  { parentRegion: 'Nordland', id: 1856, name: 'Røst', postfix: [] },
  {
    parentRegion: 'Trøndelag',
    id: 5043,
    name: 'Røyrvik',
    postfix: ['Raarvihke'],
  },
  { parentRegion: 'Østfold', id: 3112, name: 'Råde', postfix: [] },
  { parentRegion: 'Troms', id: 5522, name: 'Salangen', postfix: [] },
  { parentRegion: 'Nordland', id: 1840, name: 'Saltdal', postfix: [] },
  { parentRegion: 'Vestland', id: 4623, name: 'Samnanger', postfix: [] },
  { parentRegion: 'Møre og Romsdal', id: 1514, name: 'Sande', postfix: [] },
  { parentRegion: 'Vestfold', id: 3907, name: 'Sandefjord', postfix: [] },
  { parentRegion: 'Rogaland', id: 1108, name: 'Sandnes', postfix: [] },
  { parentRegion: 'Østfold', id: 3105, name: 'Sarpsborg', postfix: [] },
  { parentRegion: 'Rogaland', id: 1135, name: 'Sauda', postfix: [] },
  { parentRegion: 'Innlandet', id: 3437, name: 'Sel', postfix: [] },
  { parentRegion: 'Trøndelag', id: 5032, name: 'Selbu', postfix: [] },
  { parentRegion: 'Telemark', id: 4022, name: 'Seljord', postfix: [] },
  { parentRegion: 'Troms', id: 5530, name: 'Senja', postfix: [] },
  { parentRegion: 'Buskerud', id: 3332, name: 'Sigdal', postfix: [] },
  { parentRegion: 'Telemark', id: 4010, name: 'Siljan', postfix: [] },
  { parentRegion: 'Agder', id: 4228, name: 'Sirdal', postfix: [] },
  { parentRegion: 'Trøndelag', id: 5029, name: 'Skaun', postfix: [] },
  { parentRegion: 'Telemark', id: 4003, name: 'Skien', postfix: [] },
  { parentRegion: 'Østfold', id: 3116, name: 'Skiptvet', postfix: [] },
  { parentRegion: 'Troms', id: 5542, name: 'Skjervøy', postfix: [] },
  { parentRegion: 'Innlandet', id: 3433, name: 'Skjåk', postfix: [] },
  { parentRegion: 'Møre og Romsdal', id: 1573, name: 'Smøla', postfix: [] },
  { parentRegion: 'Trøndelag', id: 5041, name: 'Snåsa', postfix: ['Snåase'] },
  { parentRegion: 'Vestland', id: 4640, name: 'Sogndal', postfix: [] },
  { parentRegion: 'Rogaland', id: 1111, name: 'Sokndal', postfix: [] },
  { parentRegion: 'Rogaland', id: 1124, name: 'Sola', postfix: [] },
  { parentRegion: 'Vestland', id: 4636, name: 'Solund', postfix: [] },
  { parentRegion: 'Nordland', id: 1870, name: 'Sortland', postfix: ['Suortá'] },
  { parentRegion: 'Vestland', id: 4649, name: 'Stad', postfix: [] },
  { parentRegion: 'Innlandet', id: 3413, name: 'Stange', postfix: [] },
  { parentRegion: 'Rogaland', id: 1103, name: 'Stavanger', postfix: [] },
  { parentRegion: 'Nordland', id: 1848, name: 'Steigen', postfix: [] },
  { parentRegion: 'Trøndelag', id: 5006, name: 'Steinkjer', postfix: [] },
  { parentRegion: 'Trøndelag', id: 5035, name: 'Stjørdal', postfix: [] },
  { parentRegion: 'Innlandet', id: 3423, name: 'Stor-Elvdal', postfix: [] },
  { parentRegion: 'Vestland', id: 4614, name: 'Stord', postfix: [] },
  {
    parentRegion: 'Troms',
    id: 5538,
    name: 'Storfjord',
    postfix: ['Omasvuotna', 'Omasvuono'],
  },
  { parentRegion: 'Rogaland', id: 1130, name: 'Strand', postfix: [] },
  { parentRegion: 'Møre og Romsdal', id: 1525, name: 'Stranda', postfix: [] },
  { parentRegion: 'Vestland', id: 4651, name: 'Stryn', postfix: [] },
  { parentRegion: 'Møre og Romsdal', id: 1531, name: 'Sula', postfix: [] },
  { parentRegion: 'Rogaland', id: 1134, name: 'Suldal', postfix: [] },
  { parentRegion: 'Møre og Romsdal', id: 1563, name: 'Sunndal', postfix: [] },
  { parentRegion: 'Vestland', id: 4647, name: 'Sunnfjord', postfix: [] },
  { parentRegion: 'Møre og Romsdal', id: 1566, name: 'Surnadal', postfix: [] },
  { parentRegion: 'Svalbard', id: 2121, name: 'Svalbard' },
  { parentRegion: 'Vestland', id: 4612, name: 'Sveio', postfix: [] },
  { parentRegion: 'Møre og Romsdal', id: 1528, name: 'Sykkylven', postfix: [] },
  { parentRegion: 'Nordland', id: 1812, name: 'Sømna', postfix: [] },
  { parentRegion: 'Innlandet', id: 3447, name: 'Søndre Land', postfix: [] },
  { parentRegion: 'Innlandet', id: 3449, name: 'Sør-Aurdal', postfix: [] },
  { parentRegion: 'Innlandet', id: 3438, name: 'Sør-Fron', postfix: [] },
  { parentRegion: 'Innlandet', id: 3415, name: 'Sør-Odal', postfix: [] },
  { parentRegion: 'Finnmark', id: 5605, name: 'Sør-Varanger', postfix: [] },
  { parentRegion: 'Nordland', id: 1845, name: 'Sørfold', postfix: [] },
  { parentRegion: 'Troms', id: 5526, name: 'Sørreisa', postfix: [] },
  { parentRegion: 'Finnmark', id: 5628, name: 'Tana', postfix: ['Deatnu'] },
  { parentRegion: 'Rogaland', id: 1121, name: 'Time', postfix: [] },
  { parentRegion: 'Møre og Romsdal', id: 1560, name: 'Tingvoll', postfix: [] },
  { parentRegion: 'Telemark', id: 4026, name: 'Tinn', postfix: [] },
  {
    parentRegion: 'Troms',
    id: 5512,
    name: 'Tjeldsund',
    postfix: ['Dielddanuorri'],
  },
  { parentRegion: 'Telemark', id: 4034, name: 'Tokke', postfix: [] },
  { parentRegion: 'Innlandet', id: 3426, name: 'Tolga', postfix: [] },
  { parentRegion: 'Troms', id: 5501, name: 'Tromsø', postfix: [] },
  {
    parentRegion: 'Trøndelag',
    id: 5001,
    name: 'Trondheim',
    postfix: ['Tråante'],
  },
  { parentRegion: 'Innlandet', id: 3421, name: 'Trysil', postfix: [] },
  { parentRegion: 'Nordland', id: 1835, name: 'Træna', postfix: [] },
  { parentRegion: 'Agder', id: 4213, name: 'Tvedestrand', postfix: [] },
  { parentRegion: 'Trøndelag', id: 5033, name: 'Tydal', postfix: [] },
  { parentRegion: 'Innlandet', id: 3427, name: 'Tynset', postfix: [] },
  { parentRegion: 'Vestland', id: 4616, name: 'Tysnes', postfix: [] },
  { parentRegion: 'Rogaland', id: 1146, name: 'Tysvær', postfix: [] },
  { parentRegion: 'Vestfold', id: 3905, name: 'Tønsberg', postfix: [] },
  { parentRegion: 'Akershus', id: 3209, name: 'Ullensaker', postfix: [] },
  { parentRegion: 'Vestland', id: 4618, name: 'Ullensvang', postfix: [] },
  { parentRegion: 'Møre og Romsdal', id: 1516, name: 'Ulstein', postfix: [] },
  { parentRegion: 'Vestland', id: 4620, name: 'Ulvik', postfix: [] },
  { parentRegion: 'Rogaland', id: 1151, name: 'Utsira', postfix: [] },
  { parentRegion: 'Finnmark', id: 5607, name: 'Vadsø', postfix: [] },
  { parentRegion: 'Vestland', id: 4628, name: 'Vaksdal', postfix: [] },
  { parentRegion: 'Agder', id: 4221, name: 'Valle', postfix: [] },
  { parentRegion: 'Innlandet', id: 3454, name: 'Vang', postfix: [] },
  { parentRegion: 'Møre og Romsdal', id: 1511, name: 'Vanylven', postfix: [] },
  { parentRegion: 'Finnmark', id: 5634, name: 'Vardø', postfix: [] },
  { parentRegion: 'Nordland', id: 1824, name: 'Vefsn', postfix: [] },
  { parentRegion: 'Nordland', id: 1815, name: 'Vega', postfix: [] },
  { parentRegion: 'Agder', id: 4212, name: 'Vegårshei', postfix: [] },
  { parentRegion: 'Agder', id: 4223, name: 'Vennesla', postfix: [] },
  { parentRegion: 'Trøndelag', id: 5038, name: 'Verdal', postfix: [] },
  { parentRegion: 'Akershus', id: 3216, name: 'Vestby', postfix: [] },
  { parentRegion: 'Møre og Romsdal', id: 1535, name: 'Vestnes', postfix: [] },
  { parentRegion: 'Innlandet', id: 3452, name: 'Vestre Slidre', postfix: [] },
  { parentRegion: 'Innlandet', id: 3443, name: 'Vestre Toten', postfix: [] },
  { parentRegion: 'Nordland', id: 1860, name: 'Vestvågøy', postfix: [] },
  { parentRegion: 'Nordland', id: 1816, name: 'Vevelstad', postfix: [] },
  { parentRegion: 'Vestland', id: 4639, name: 'Vik', postfix: [] },
  { parentRegion: 'Rogaland', id: 1160, name: 'Vindafjord', postfix: [] },
  { parentRegion: 'Telemark', id: 4036, name: 'Vinje', postfix: [] },
  { parentRegion: 'Møre og Romsdal', id: 1577, name: 'Volda', postfix: [] },
  { parentRegion: 'Vestland', id: 4621, name: 'Voss', postfix: [] },
  { parentRegion: 'Nordland', id: 1857, name: 'Værøy', postfix: [] },
  { parentRegion: 'Nordland', id: 1865, name: 'Vågan', postfix: [] },
  { parentRegion: 'Innlandet', id: 3435, name: 'Vågå', postfix: [] },
  { parentRegion: 'Østfold', id: 3114, name: 'Våler', postfix: ['Østfold'] },
  {
    parentRegion: 'Innlandet',
    id: 3419,
    name: 'Våler',
    postfix: ['Innlandet'],
  },
  { parentRegion: 'Nordland', id: 1868, name: 'Øksnes', postfix: [] },
  { parentRegion: 'Trøndelag', id: 5057, name: 'Ørland', postfix: [] },
  { parentRegion: 'Møre og Romsdal', id: 1520, name: 'Ørsta', postfix: [] },
  { parentRegion: 'Innlandet', id: 3442, name: 'Østre Toten', postfix: [] },
  { parentRegion: 'Buskerud', id: 3314, name: 'Øvre Eiker', postfix: [] },
  { parentRegion: 'Innlandet', id: 3440, name: 'Øyer', postfix: [] },
  { parentRegion: 'Vestland', id: 4626, name: 'Øygarden', postfix: [] },
  { parentRegion: 'Innlandet', id: 3453, name: 'Øystre Slidre', postfix: [] },
  { parentRegion: 'Trøndelag', id: 5058, name: 'Åfjord', postfix: [] },
  { parentRegion: 'Buskerud', id: 3328, name: 'Ål', postfix: [] },
  { parentRegion: 'Møre og Romsdal', id: 1508, name: 'Ålesund', postfix: [] },
  { parentRegion: 'Agder', id: 4217, name: 'Åmli', postfix: [] },
  { parentRegion: 'Innlandet', id: 3422, name: 'Åmot', postfix: [] },
  { parentRegion: 'Vestland', id: 4643, name: 'Årdal', postfix: [] },
  { parentRegion: 'Akershus', id: 3218, name: 'Ås', postfix: [] },
  { parentRegion: 'Agder', id: 4224, name: 'Åseral', postfix: [] },
  { parentRegion: 'Innlandet', id: 3418, name: 'Åsnes', postfix: [] },
];

export const regionIdMap: Record<number, Region | undefined> = regions.reduce(
  (tempMap, region) => {
    return { ...tempMap, [region.id]: region };
  },
  {}
);
