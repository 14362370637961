<script lang="ts">
  export let onClick = () => {};
  export let blink = false;
</script>

<div class:blink>
  <div class="primary-button__container">
    <button class="primary-button" on:click={onClick}><slot></slot></button>
  </div>
</div>

<style>
  .primary-button__container {
    display: flex;
    background-color: transparent;
    filter: drop-shadow(0px 0px 18px var(--red));
  }

  .primary-button {
    display: block;
    margin: 0 auto;
    --corner-size: 0.75rem;
    clip-path: polygon(
      0 0,
      calc(100% - var(--corner-size)) 0,
      100% var(--corner-size),
      100% 100%,
      var(--corner-size) 100%,
      0 calc(100% - var(--corner-size))
    );
    color: var(--black);
    background-color: var(--red);
    padding: 1rem 2rem;
    font-size: 0.875rem;
    font-weight: 700;
    letter-spacing: 0.5px;
    text-transform: uppercase;
    text-align: center;
    cursor: pointer;
  }
  .blink {
    position: relative;
    width: 289px;
    height: 45px;
  }

  .blink:before {
    content: '';
    background-image: url('../assets/btn-blink.svg');
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    width: 289px;
    height: 88px;
    display: block;
    position: absolute;
    top: 0;
    left: 0;
  }
</style>
