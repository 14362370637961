<script lang="ts">
  import { nrkChevronDown, nrkChevronUp } from '@nrk/core-icons';
  import { fade, fly } from 'svelte/transition';
  import { trackEvent } from '../../util/snowplow';
  import FavoriteButton from '../Buttons/FavoriteButton/FavoriteButton.svelte';
  import InfoButton from '../Buttons/InfoButton.svelte';
  import Countdown from '../Countdown/Countdown.svelte';
  import { roundPaused } from '../Modal/locked-state.svelte';
  import GuessBox from './GuessBox.svelte';

  export let participant: Maskorama.Participant;

  const duration = 300;
  const y = 153;

  const toggleContent = () => {
    hideContent = !hideContent;
    setTimeout(() => {
      document.querySelector<HTMLButtonElement>('.toggle-button')?.focus();
    }, duration + 100);

    trackEvent({
      action: hideContent
        ? 'active-participant:hide'
        : 'active-participant:show',
      label: `participant:${participant.id}`,
    });
  };
  let hideContent = false;
</script>

<div class="content">
  {#if !hideContent}
    <div class="guess-box" transition:fly={{ y, duration }}>
      <GuessBox {participant} isLocked={$roundPaused === 'paused'} />
    </div>
  {/if}

  <div class="button-wrapper">
    {#if !hideContent}
      <div class="info-and-countdown" transition:fly={{ y, duration }}>
        <button
          class="toggle-button"
          on:click={toggleContent}
          aria-label="Skjul gjetteboks"
        >
          <!-- eslint-disable-next-line svelte/no-at-html-tags -->
          {@html nrkChevronDown}
        </button>

        <div>
          <InfoButton />
        </div>

        <Countdown largefixed={false} />
      </div>

      <div transition:fly={{ y, duration }}>
        <FavoriteButton {participant} isLocked={$roundPaused === 'paused'} />
      </div>
    {:else}
      <button
        class="toggle-button hidden"
        in:fade={{ duration }}
        on:click={toggleContent}
        aria-label="Vis gjetteboks"
      >
        <!-- eslint-disable-next-line svelte/no-at-html-tags -->
        {@html nrkChevronUp}
      </button>
    {/if}
  </div>
</div>

<style>
  .toggle-button {
    border-radius: 999px;
    z-index: 1000;
    background: var(--red);
    color: black;
    display: inline;
    display: grid;
    place-content: center;
    height: 44px;
    width: 44px;
  }

  .toggle-button.hidden {
    position: absolute;
    bottom: 1rem;
  }

  .content {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column-reverse;
    align-items: center;
    padding: 0 1rem 1rem;
    justify-content: start-end;
  }
  .button-wrapper {
    display: flex;
    justify-content: space-between;
    align-items: center;
    max-width: 40rem;
    width: 100%;
  }
  .info-and-countdown {
    display: flex;
    gap: 1rem;
    align-items: center;
    align-self: flex-end;
    margin-bottom: 1rem;
  }

  .guess-box {
    width: 100%;
    max-width: 40rem;
  }
</style>
