<script lang="ts">
  /* eslint svelte/no-at-html-tags: "off" */
  import { nrkLockActive } from '@nrk/core-icons';
  import { onMount } from 'svelte';
  let heading: HTMLHeadingElement;

  onMount(() => {
    if (heading != null) {
      heading.focus();
    }
  });
</script>

<div class="content">
  <div class="icon">
    {@html nrkLockActive}
  </div>
  <h2 tabindex="-1" bind:this={heading}>Gjettingen er nå låst!</h2>
  <p>
    Akkurat nå henter vi inn forslag og favoritter fra hele Norge. Du kan
    dessverre ikke gjette eller velge favoritter mens opptellingen pågår.
  </p>
  <p>
    Deltakeren med færrest favorittmarkeringer må ta av seg masken. Følg med i
    TV-sendingen, snart kommer den store avsløringen!
  </p>
</div>

<style>
  .content {
    display: grid;
    place-content: center;
    max-width: 30rem;
    height: 70%;
  }
  p,
  div,
  h2 {
    text-align: center;
    margin-top: 1rem;
  }

  h2 {
    text-transform: uppercase;
  }

  p {
    color: white;
    margin: 2rem 0 1rem;
  }

  div {
    color: #f5255b;
  }
</style>
