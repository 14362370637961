import type { Writable, Readable } from 'svelte/store';
import { Observable } from 'rxjs';

/**
 * Converts a Svelte store to an Observable.
 */
export const fromSvelteStore = <T>(svelteStore: Writable<T> | Readable<T>) =>
  new Observable<T>((subscriber) =>
    svelteStore.subscribe((val) => {
      subscriber.next(val);
    })
  );
