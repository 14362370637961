import { derived, get, writable } from 'svelte/store';
import { loginModel } from '../lib/components/Login/loginModel';
import { AbortApiRetryException } from './utils/abortRetryException';
import { getTokenData } from './utils/getTokenData';
import { HttpError } from './utils/HttpError';
import { retryAfterMs } from './utils/retryAfter';
import { TokenError } from './utils/TokenError';
import { trackEvent } from '../lib/util/snowplow';

const FETCH_ROUND_INTERVAL = 20_000;

export interface WorkData {
  participants: Array<Maskorama.Participant>;
  round?: Maskorama.Round;
}
export const workData = writable<WorkData>({
  participants: [],
});

export const maskedParticipants = derived(workData, ($workData) =>
  $workData.participants.filter(
    (p) => !p.unmasked && p.realName === undefined && !p.rescued
  )
);

export const unmaskedParticipants = derived(workData, ($workData) => {
  return $workData.participants.filter(
    (p) => p.unmasked || p.realName !== undefined || p.rescued
  );
});

const endRoundIfRequestWasRejectedBecauseRoundHasEndedOnBackend = (
  statusCode: number
) => {
  // This took me a long time to understand, so I put it in a function with an ugly name.
  // If we get 409 when sending guess or toggling favorite, it means that the round has ended,
  // and the client does not know it yet. Hence the fuzzy code below which results in the the round
  // ending at the client. Hopefully..
  if (statusCode === 409) {
    workData.update((curr) => ({
      ...curr,
      round: { endTime: new Date().getTime() },
    }));
    trackEvent({ action: 'round:ended-by-409' });
    return true;
  }
  return false;
};

let shouldRefresh = document.visibilityState === 'visible';
let hasActiveRequest = false;
let dataSyncInitialized = false;

document.addEventListener('visibilitychange', () => {
  if (dataSyncInitialized) {
    const isActive = document.visibilityState === 'visible';
    const wasActivated = isActive && !shouldRefresh;
    if (wasActivated && !hasActiveRequest) {
      getRoundData();
    }
    shouldRefresh = isActive;
  }
});

const getRoundData = async function () {
  try {
    const response = await fetch(
      `${import.meta.env.VITE_API_URL}api/rounds/current`
    );
    if (!response.ok) {
      throw new HttpError(response);
    }
    const data = (await response.json()) as Maskorama.Round;
    workData.update((currentData) => ({ ...currentData, round: data }));
  } catch (_ex) {
    // captureRequestError(ex);
    // This will be retried every 20 seconds, so far we only get network errors, so
    // no need to log every time this fails.
  } finally {
    hasActiveRequest = false;
    if (shouldRefresh) {
      hasActiveRequest = true;
      setTimeout(getRoundData, FETCH_ROUND_INTERVAL);
    }
  }
};

export const initDataSync = () => {
  dataSyncInitialized = true;
  hasActiveRequest = true;
  getRoundData();

  return getParticipants();
};

// Get all participants

export const getParticipants = async function () {
  const participants = await fetchParticipants();
  workData.update((currentData) => ({ ...currentData, participants }));

  return participants;
};

const retryDelays = [1000, 2000, 3000, 6000];
const fetchParticipants = async (): Promise<Maskorama.Participant[]> =>
  fetchWithRetry<Maskorama.Participant[]>(async () => {
    const tokenData = await getTokenData();
    const abortController = new AbortController();
    const timeoutId = setTimeout(() => abortController.abort(), 2_000);
    const response = await fetch(
      `${import.meta.env.VITE_API_URL}api/participants`,
      {
        method: 'GET',
        headers: {
          authorization: `Bearer ${tokenData.accessToken}`,
          'content-type': 'application/json',
        },
        signal: abortController.signal,
      }
    );
    clearTimeout(timeoutId);

    if (!response.ok) {
      throw new HttpError(response);
    }
    return response.json();
  });

const forceRefreshIfApplicable = async (error: any, retryCount: number) => {
  if (retryCount > 0) {
    return;
  }
  if (error instanceof TokenError) {
    return await loginModel.forceRefresh();
  }
  if (error instanceof HttpError && error.statusCode === 401) {
    return await loginModel.forceRefresh();
  }
  if (error instanceof HttpError && error.statusCode === 403) {
    return await loginModel.forceRefresh();
  }
};

async function fetchWithRetry<T>(
  action: () => Promise<T>,
  options: { skippableErrors?: number[] } = {},
  previousError?: any,
  retryCount = 0
): Promise<T> {
  if (retryCount >= retryDelays.length) {
    // captureRequestError(previousError);
    throw new AbortApiRetryException('Retrycount exceeded');
  }
  try {
    return await action();
  } catch (error: any) {
    await forceRefreshIfApplicable(error, retryCount);

    const skippableErrors = options?.skippableErrors ?? [];
    if (
      error instanceof AbortApiRetryException ||
      skippableErrors.includes(error.statusCode)
    ) {
      // captureRequestError(previousError);
      throw error;
    }

    let retryAfter = 0;
    if (error instanceof HttpError) {
      retryAfter = retryAfterMs(error.response) ?? 0;
    }
    await delay(Math.max(retryAfter, retryDelays[retryCount]));
    return fetchWithRetry(action, options, error, retryCount + 1);
  }
}

const delay = (waitTime = 0) => {
  return new Promise((resolve) => {
    setTimeout(resolve, waitTime);
  });
};

const setMyGuessFunc = async function (
  participantId: number,
  guessedName: string
) {
  const participants = get(workData).participants;
  try {
    const index = participants.findIndex((part) => part.id === participantId);
    const tokenData = await getTokenData();
    if (guessedName === '') {
      const abortController = new AbortController();
      const timeoutId = setTimeout(() => abortController.abort(), 2_000);
      const response = await fetch(
        `${import.meta.env.VITE_API_URL}api/guesses`,
        {
          method: 'DELETE',
          headers: {
            authorization: `Bearer ${tokenData.accessToken}`,
            'content-type': 'application/json',
          },
          body: JSON.stringify({ participantId }),
          signal: abortController.signal,
        }
      );
      clearTimeout(timeoutId);
      if (!response.ok) {
        throw new HttpError(response);
      }
      delete participants[index].guessedName;
    } else {
      const abortController = new AbortController();
      const timeoutId = setTimeout(() => abortController.abort(), 2_000);
      const response = await fetch(
        `${import.meta.env.VITE_API_URL}api/guesses`,
        {
          method: 'POST',
          headers: {
            authorization: `Bearer ${tokenData.accessToken}`,
            'content-type': 'application/json',
          },
          body: JSON.stringify({ participantId, guessedName }),
          signal: abortController.signal,
        }
      );
      clearTimeout(timeoutId);
      if (!response.ok) {
        throw new HttpError(response);
      }
      const data = await response.json();
      participants[index].guessedName = data.guessedName;
    }
  } catch (ex: any) {
    if (
      endRoundIfRequestWasRejectedBecauseRoundHasEndedOnBackend(ex.statusCode)
    ) {
      throw new AbortApiRetryException('round has ended');
    }
    throw ex;
  }

  workData.update((currentData) => ({ ...currentData, participants }));
  return participants.find((part) => part.id === participantId)?.guessedName;
};

export const setMyGuess = async (
  participantId: number,
  guessedName: string
): Promise<string | undefined> => {
  try {
    return await fetchWithRetry<string | undefined>(
      () => setMyGuessFunc(participantId, guessedName),
      {
        skippableErrors: [404],
      }
    );
  } catch (_error) {
    trackEvent({ action: 'error:setguess' });
    return undefined;
  }
};

const toggleFavoritesFunc = async function (
  participantId: number,
  toggle = true
) {
  const participants = get(workData).participants;
  try {
    const tokenData = await getTokenData();
    if (toggle) {
      const abortController = new AbortController();
      const timeoutId = setTimeout(() => abortController.abort(), 2_000);
      const response = await fetch(
        `${import.meta.env.VITE_API_URL}api/favorites`,
        {
          method: 'POST',
          headers: {
            authorization: `Bearer ${tokenData.accessToken}`,
            'content-type': 'application/json',
          },
          body: JSON.stringify({ participantId }),
          signal: abortController.signal,
        }
      );
      clearTimeout(timeoutId);
      if (!response.ok) {
        throw new HttpError(response);
      }
    } else {
      try {
        const abortController = new AbortController();
        const timeoutId = setTimeout(() => abortController.abort(), 2_000);
        const response = await fetch(
          `${import.meta.env.VITE_API_URL}api/favorites`,
          {
            method: 'DELETE',
            headers: {
              authorization: `Bearer ${tokenData.accessToken}`,
              'content-type': 'application/json',
            },
            body: JSON.stringify({ participantId }),
            signal: abortController.signal,
          }
        );
        clearTimeout(timeoutId);
        if (!response.ok) {
          throw new HttpError(response);
        }
      } catch (error) {
        // If error is 404 we want to follow normal flow ending in returning the changed participant
        // so we only throw if it is not 404.
        if (
          !(error instanceof HttpError) ||
          (error instanceof HttpError && error.statusCode !== 404)
        ) {
          throw error;
        }
      }
    }
    const participant = participants.find((part) => part.id === participantId);
    if (participant) {
      participant.favorite = toggle;
    }
  } catch (ex: any) {
    if (
      endRoundIfRequestWasRejectedBecauseRoundHasEndedOnBackend(ex.statusCode)
    ) {
      throw new AbortApiRetryException('round has ended');
    }
    trackEvent({ action: 'error:togglefavorite', label: `${toggle}` });
    throw ex;
  }

  workData.update((currentData) => ({ ...currentData, participants }));
  return participants;
};

export const toggleFavorites = async (
  participantId: number,
  toggle = true
): Promise<Maskorama.Participant[] | null> => {
  try {
    return await fetchWithRetry<Maskorama.Participant[]>(() =>
      toggleFavoritesFunc(participantId, toggle)
    );
  } catch (_error) {
    return null;
  }
};

export async function unmaskParticipant(participantId: number) {
  return fetchWithRetry(async () => {
    const participants = get(workData).participants;
    const tokenData = await getTokenData();
    const abortController = new AbortController();
    const timeoutId = setTimeout(() => abortController.abort(), 2_000);
    const response = await fetch(
      `${import.meta.env.VITE_API_URL}api/participants/${participantId}/unmask`,
      {
        method: 'POST',
        headers: {
          authorization: `Bearer ${tokenData.accessToken}`,
        },
        signal: abortController.signal,
      }
    );
    clearTimeout(timeoutId);
    if (!response.ok) {
      throw new HttpError(response);
    }
    const participant = participants.find((part) => part.id === participantId);
    if (participant) {
      participant.unmasked = true;
    }

    workData.update((currentData) => ({ ...currentData, participants }));
    return participants;
  });
}
