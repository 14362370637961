<script lang="ts">
  export let height = 47;
</script>

<svg
  style="height: {height}px"
  width="327"
  height="47"
  viewBox="0 0 327 47"
  fill="none"
  xmlns="http://www.w3.org/2000/svg"
  aria-hidden="true"
>
  <rect
    x="68.3286"
    width="6"
    height="6"
    transform="rotate(45 68.3286 0)"
    fill="#F5255B"
  >
    <animate
      attributeName="opacity"
      values="0;1;0"
      dur="2.3s"
      repeatCount="indefinite"
    />
  </rect>
  <rect
    x="4.32861"
    y="38"
    width="6"
    height="6"
    transform="rotate(45 4.32861 38)"
    fill="#F5255B"
  >
    <animate
      attributeName="opacity"
      values="1;0;1"
      dur="2.4s"
      repeatCount="indefinite"
    />
  </rect>
  <rect
    x="252.243"
    y="27"
    width="6"
    height="6"
    transform="rotate(45 252.243 27)"
    fill="#F5255B"
  >
    <animate
      attributeName="opacity"
      values="0;1;0"
      dur="2.1s"
      repeatCount="indefinite"
    />
  </rect>
  <rect
    x="291.243"
    y="14"
    width="6"
    height="6"
    transform="rotate(45 291.243 14)"
    fill="#F5255B"
  >
    <animate
      attributeName="opacity"
      values="1;0;1"
      dur="2.6s"
      repeatCount="indefinite"
    />
  </rect>
  <rect
    x="324.165"
    y="27"
    width="4"
    height="4"
    transform="rotate(45 324.165 27)"
    fill="#40E0F2"
  >
    <animate
      attributeName="opacity"
      values="0;1;0"
      dur="2.4s"
      repeatCount="indefinite"
    />
  </rect>
  <rect
    x="265.828"
    y="5"
    width="4"
    height="4"
    transform="rotate(45 265.828 5)"
    fill="#40E0F2"
  >
    <animate
      attributeName="opacity"
      values="1;0;1"
      dur="1s"
      repeatCount="indefinite"
    />
  </rect>
  <rect
    x="30.8284"
    y="12"
    width="4"
    height="4"
    transform="rotate(45 30.8284 12)"
    fill="#40E0F2"
  >
    <animate
      attributeName="opacity"
      values="0;1;0"
      dur="2.2s"
      repeatCount="indefinite"
    />
  </rect>
  <rect
    x="59.1646"
    y="38"
    width="4"
    height="4"
    transform="rotate(45 59.1646 38)"
    fill="#40E0F2"
  >
    <animate
      attributeName="opacity"
      values="0;1;0"
      dur="2.4s"
      repeatCount="indefinite"
    />
  </rect>
</svg>
