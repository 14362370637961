<script lang="ts">
  import { onMount } from 'svelte';
  import { link } from 'svelte-spa-router';
  import biggong from '../Three/gong.webp';
  import SmallGong from '../Scoreboard/SVGs/SmallGong.svelte';
  import { rescuedparticipant } from './rescued.svelte';
  import { getFocusId, previousFocus } from './previousFocusElement.svelte';

  let { participant }: { participant: Maskorama.Participant } = $props();
  let linkElement: HTMLAnchorElement | undefined;
  let hiderescuedparticipant = $derived(rescuedparticipant.hidden);
  let focusId = $derived(getFocusId(participant.id));

  onMount(() => {
    if (previousFocus.id == focusId) {
      linkElement?.focus();
      previousFocus.removeFocusId();
    }
  });
</script>

<a
  class="participant-wrapper"
  class:hiderescuedparticipant
  id={`#rescuedparticipant-${participant.id}`}
  bind:this={linkElement}
  use:link
  href="/participant/{participant.id}"
>
  {#if hiderescuedparticipant === false}
    <div class="smile-wrapper">
      <div class="smile">
        <SmallGong />
      </div>
      <div class="smile-text">Reddet</div>
    </div>
  {/if}
  <div class="participant-box-border">
    <div class="participant-content">
      {#if hiderescuedparticipant}
        <h2>Gong!</h2>
        <p>
          <span class="text">Sjekk hvem panelet reddet!</span>
        </p>
      {:else}
        <h2>
          {`${participant.maskName} ble reddet!`}
        </h2>
        <p>
          <span class="revealed-text"
            >{`Panelet reddet ${participant.maskName} fra å ta av masken denne uken`}</span
          >
        </p>
      {/if}
    </div>
    <img src={biggong} alt="Gong!" />
  </div>
</a>

<style>
  .participant-wrapper {
    display: block;
    position: relative;
    margin-top: 0.5rem;
    transition: transform 0.2s;
    filter: drop-shadow(1px 1px 18px var(--purple));
  }

  .participant-wrapper:hover,
  .participant-wrapper:focus {
    transform: scale(1.05);
  }

  .smile-wrapper {
    position: absolute;
    display: flex;
    align-items: center;
    left: -0.5rem;
    top: -0.7rem;
    text-transform: uppercase;
    font-size: 0.8rem;
  }
  .smile {
    z-index: 2;
    transform: rotate(-25deg);
  }

  .smile-text {
    z-index: 1;
    color: var(--white);
    white-space: nowrap;
    padding: 0.2rem 1rem 0.2rem 1.5rem;
    margin-left: -1rem;
    margin-top: -0.2rem;
    text-align: center;
    --corner-size: 0.5rem;
    text-decoration: none;
    background-color: var(--purple);
    clip-path: polygon(
      0 0,
      calc(100% - var(--corner-size)) 0,
      100% var(--corner-size),
      100% 100%,
      var(--corner-size) 100%,
      0 calc(100% - var(--corner-size))
    );
  }

  .participant-box-border {
    --border-size: 0.2rem;
    --right-top-corner-size: 7.5rem;
    --left-bottom-corner-size: 0.75rem;
    --background-color-size: calc(
      var(--left-bottom-corner-size) + var(--border-size) +
        (var(--border-size) / 2)
    );
    --bg-border-color: var(--purple);
    text-decoration: none;
    /* background: transparent; */
    background: linear-gradient(
      90deg,
      var(--bg-border-color) 0%,
      var(--bg-border-color) var(--background-color-size),
      transparent var(--background-color-size),
      transparent 100%
    );
    clip-path: polygon(
      0 0,
      calc(100% - var(--right-top-corner-size)) 0,
      100% var(--right-top-corner-size),
      100% 100%,
      var(--left-bottom-corner-size) 100%,
      0 calc(100% - var(--left-bottom-corner-size))
    );
  }

  .hiderescuedparticipant .participant-box-border {
    --bg-border-color: transparent;
  }

  .participant-content {
    --zero-position: calc(0% + var(--border-size));
    --hundred-position: calc(100% - var(--border-size));
    --half-border: calc(var(--border-size) / 2);
    --gradient-color: var(--black);
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 0.5rem;
    min-height: 8.5rem;
    max-width: 100%;
    padding: 1rem 1.5rem;
    background: linear-gradient(
      0deg,
      var(--gradient-color) 0%,
      var(--gradient-color) 100%
    );
    color: white;
    clip-path: polygon(
      var(--zero-position) 0,
      100% 0,
      100% 100%,
      calc(var(--left-bottom-corner-size) + (var(--border-size) * 1.5)) 100%,
      calc(var(--left-bottom-corner-size))
        calc(var(--hundred-position) - var(--half-border)),
      var(--zero-position)
        calc(
          var(--hundred-position) -
            (var(--left-bottom-corner-size) - var(--half-border))
        )
    );
  }

  .hiderescuedparticipant .participant-content {
    --gradient-color: var(--purple);
  }
  .participant-content > p {
    /* this is how to make text ellipsis that spans multiple lines 
        works cross browser even if it is a bit heavy webkitty in
        propnames */
    max-width: 68%;
    display: -webkit-box;
    overflow: hidden;
    -webkit-line-clamp: 3; /* number of lines to show */
    line-clamp: 3;
    -webkit-box-orient: vertical;
  }

  .text {
    z-index: 2;
    position: relative;
  }

  h2 {
    /* 100% - width of image */
    max-width: calc(100% - 115px);
    margin-top: 0.5rem;
    font-size: 1rem;
    font-weight: 600;
    color: var(--white);
  }

  .hiderescuedparticipant p {
    font-size: 0.875rem;
    font-weight: 700;
    margin-top: 0.25rem;
    text-transform: uppercase;
    letter-spacing: 0.05em;
  }

  p {
    font-size: 1.25rem;
  }

  img {
    position: absolute;
    right: 0;
    bottom: 0;
    z-index: 1;
    width: 8rem;
    padding-bottom: 0.5rem;
  }
</style>
