export const userPrefersReducedMotion = () => {
  const match =
    window.matchMedia && window.matchMedia('(prefers-reduced-motion: reduce)');
  return match && match.matches;
};

let shouldAutoPlay = false;
let hasCheckedAutoPlay = false;

const checkAndSetAutoPlay = () => {
  try {
    hasCheckedAutoPlay = true;
    if (userPrefersReducedMotion()) {
      shouldAutoPlay = false;
      return shouldAutoPlay;
    }
    shouldAutoPlay = true;
    return shouldAutoPlay;
  } catch (_error) {
    return shouldAutoPlay;
  }
};

export const checkAutoplay = () => {
  if (hasCheckedAutoPlay) {
    return shouldAutoPlay;
  }
  return checkAndSetAutoPlay();
};
