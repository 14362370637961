<script>
  export let className = '';
  export let style = '';
</script>

<svg
  class={className}
  {style}
  aria-hidden="true"
  viewBox="0 0 110 117"
  fill="none"
  xmlns="http://www.w3.org/2000/svg"
>
  <rect
    x="88.2427"
    y="9"
    width="6"
    height="6"
    transform="rotate(45 88.2427 9)"
    fill="#F5255B"
  >
    <animate
      attributeName="opacity"
      values="1;0;1"
      dur="2.3s"
      repeatCount="indefinite"
    />
  </rect>
  <rect
    x="3"
    y="87"
    width="6"
    height="6"
    transform="rotate(45 4.24268 83)"
    fill="#F5255B"
  >
    <animate
      attributeName="opacity"
      values="1;0;1"
      dur="2s"
      repeatCount="indefinite"
    />
  </rect>
  <rect
    x="62.8284"
    width="4"
    height="4"
    transform="rotate(45 62.8284 0)"
    fill="#40E0F2"
  >
    <animate
      attributeName="opacity"
      values="1;0;1"
      dur="1.8s"
      repeatCount="indefinite"
    />
  </rect>
  <rect
    x="106.828"
    y="108"
    width="4"
    height="4"
    transform="rotate(45 106.828 108)"
    fill="#40E0F2"
  >
    <animate
      attributeName="opacity"
      values="1;0;1"
      dur="2.6s"
      repeatCount="indefinite"
    />
  </rect>
  <rect
    x="11.8284"
    y="69"
    width="4"
    height="4"
    transform="rotate(45 11.8284 69)"
    fill="#40E0F2"
  >
    <animate
      attributeName="opacity"
      values="1;0;1"
      dur="2.3s"
      repeatCount="indefinite"
    />
  </rect>
  <rect
    x="20.8284"
    y="111"
    width="4"
    height="4"
    transform="rotate(45 20.8284 111)"
    fill="#40E0F2"
  >
    <animate
      attributeName="opacity"
      values="1;0;1"
      dur="2.8s"
      repeatCount="indefinite"
    />
  </rect>
</svg>

<style>
  svg {
    overflow: visible;
  }
  .favorite-button__svg {
    grid-column: 1 / 2;
    grid-row: 1 / 2;
  }
</style>
