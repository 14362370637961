import { workData, type WorkData } from '../../../api/api';

let animationFrame: number | null;

let workDataProxy = $state<WorkData | null>(null);
let startTime = $state(0);

export const countdown = $state({ timeLeft: 0, percentage: 0 });

const start = () => {
  if (animationFrame != null || workDataProxy?.round?.endTime == null) {
    return;
  }
  const endTime = new Date(0).setUTCSeconds(workDataProxy?.round.endTime);
  startTime = Date.now();
  const totalDuration = endTime - startTime;
  countdown.timeLeft = totalDuration;

  const animationTick = () => {
    const elapsed = Date.now() - startTime;
    countdown.percentage = elapsed / totalDuration;
    countdown.timeLeft = totalDuration - elapsed;

    if (countdown.timeLeft > 0) {
      animationFrame = requestAnimationFrame(animationTick);
    } else {
      animationFrame = null;
    }
  };

  animationFrame = requestAnimationFrame(animationTick);
};

const stop = () => {
  if (animationFrame) {
    cancelAnimationFrame(animationFrame);
    animationFrame = null;
  }
};

workData.subscribe((data) => {
  workDataProxy = data;
  if (data.round?.endTime != null) {
    start();
  } else {
    stop();
  }
});
