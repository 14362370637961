import { trackEvent } from '../lib/util/snowplow';
import { HttpError } from './utils/HttpError';

export const getSuggest = async (guess: string, participantId: number) => {
  try {
    const query = new URLSearchParams({
      q: guess,
      participantId: participantId.toString(),
    });
    const response = await fetch(
      `${import.meta.env.VITE_SUGGEST_API_URL}/suggest?${query}`
    );
    if (!response.ok) {
      throw new HttpError(response);
    }
    if (response.status === 204) {
      return null;
    }
    return await response.json();
  } catch (_error) {
    trackEvent({ action: 'error:suggest-api-fail', label: `guess:${guess}` });
    return null;
  }
};
