const debounce = <T extends (...args: any[]) => void>(
  fn: T,
  delayMs: number
) => {
  let timeoutId: number;

  return (...args: Parameters<T>): void => {
    clearTimeout(timeoutId);
    timeoutId = window.setTimeout(() => fn(...args), delayMs);
  };
};

export default debounce;
