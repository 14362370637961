const unmaskedText = (
  maskName: string,
  realName: string | undefined,
  correct: boolean,
  guess = 'ikke'
) =>
  `${maskName} var ${realName}. Du gjettet ${
    correct ? 'riktig!' : `${guess}.`
  }`;
const revealedText = (maskName: string, locked: boolean) =>
  `${maskName} har blitt avslørt. ${
    locked ? 'Gjettingen er stengt. ' : 'Sjekk om du har gjettet riktig.'
  }`;

const maskedText = (
  maskName: string,
  favorite: boolean,
  locked: boolean,
  guess: string | undefined
) =>
  `${maskName}${favorite ? ' er favorittmarkert' : ''}. ${
    guess ? `Du gjettet ${guess}.` : ''
  } ${
    locked
      ? 'Gjettingen er stengt.'
      : `${guess ? 'Endre gjett.' : 'Gjett hvem.'}`
  }`;

export const getAccessibleText = (
  participant: Maskorama.Participant,
  roundPaused: boolean
) => {
  const isRevealed = participant.realName;

  const { maskName, realName, correct, guessedName, favorite, unmasked } =
    participant;

  if (isRevealed && unmasked) {
    return unmaskedText(maskName, realName, correct, guessedName);
  }
  if (isRevealed) {
    return revealedText(maskName, roundPaused);
  }

  return maskedText(maskName, favorite, roundPaused, guessedName);
};
