import { trackEvent } from '../lib/util/snowplow';
import { HttpError } from './utils/HttpError';
import { getTokenData } from './utils/getTokenData';
import * as Sentry from '@sentry/svelte';

const createLocationStore = () => {
  let location = $state<Maskorama.Location | null>(null);
  let locationJustSet = $state<boolean>(false);
  let submitting = $state<boolean>(false);

  return {
    get location() {
      return location;
    },
    setLocation: async (loc: Maskorama.Location) => {
      try {
        submitting = true;
        location = await setLocation(loc);
        if (loc.communeNumber != null) {
          locationJustSet = true;
          window.setTimeout(() => {
            locationJustSet = false;
          }, 2_000);
        }
      } catch (error) {
        location = { optedOut: true, communeNumber: null };
        trackEvent({ action: 'error:setlocation' });
        Sentry.captureException(error, { data: { loc } });
      } finally {
        submitting = false;
      }
    },
    fetchLocation: async () => {
      try {
        location = await getLocation();
      } catch (error) {
        // This is how things where handled in the old client,
        // if getLocation fails we just let the user play.
        // I think we should probably retry and finally fail instead.
        // But that is a nice to have, so lets do that when we have time.
        Sentry.captureException(error);
        location = { optedOut: true, communeNumber: null };
        trackEvent({ action: 'error:getlocation' });
      }
    },
    get isSubmitting() {
      return submitting;
    },
    get justSet() {
      return locationJustSet;
    },
  };
};

export const region = createLocationStore();

export async function getLocation(): Promise<Maskorama.Location> {
  const tokenData = await getTokenData();
  const response = await fetch(
    `${import.meta.env.VITE_API_URL}api/user/commune`,
    {
      method: 'GET',
      headers: {
        authorization: `Bearer ${tokenData.accessToken}`,
        'content-type': 'application/json',
      },
    }
  );
  if (!response.ok) {
    throw new HttpError(response);
  }
  return response.json();
}

export async function setLocation(location: Maskorama.Location) {
  const tokenData = await getTokenData();
  const response = await fetch(
    `${import.meta.env.VITE_API_URL}api/user/commune`,
    {
      method: 'POST',
      headers: {
        authorization: `Bearer ${tokenData.accessToken}`,
        'content-type': 'application/json',
      },
      body: JSON.stringify(location),
    }
  );
  if (!response.ok) {
    throw new HttpError(response);
  }
  return await response.json();
}
