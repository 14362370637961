<script lang="ts">
  import { showModal } from '../../util/stores/modalStore';
</script>

<button
  class="button"
  on:click={() => {
    showModal.set('feedback');
  }}
>
  Gi oss din tilbakemelding
</button>

<style>
  .button {
    max-width: 20rem;
    display: block;
    margin: 2rem auto;
    padding: 0.6rem 0;
    font-family: 'NRK Sans Variable';
    font-weight: 600;
    font-size: 0.75rem;
    line-height: 20px;
    text-transform: uppercase;
    text-decoration: none;
    letter-spacing: 0.2em;
    text-align: center;
    transition: all 0.2s ease-in-out;
    border: none;
    border-bottom-width: 2px;
    border-bottom-style: solid;
    text-align: center;
    transition: all 0.2s ease-in-out;
    text-transform: uppercase;
  }
</style>
