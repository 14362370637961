<script lang="ts">
  import * as Sentry from '@sentry/svelte';
  import { onMount } from 'svelte';
  import { showWebGlAnimations } from '../../util/showwebglAnimations';
  import Countdown from '../Countdown/Countdown.svelte';
  import type ThreeGong from '../Three/ThreeGong.svelte';
  import { rescuedparticipant } from './rescued.svelte';

  export let participant: Maskorama.Participant;
  let Gong: typeof ThreeGong | null = null;

  onMount(async () => {
    rescuedparticipant.revealRescuedParticipant();
    try {
      const module = await import('../Three/ThreeGong.svelte');
      Gong = module.default;
    } catch (error) {
      Sentry.captureException(error);
    }
  });
</script>

<div class="content">
  <div class="countdown-wrapper">
    <Countdown largefixed={false} />
  </div>

  <div class="rescued-banner">Gong!</div>
  <div class="info">
    <!-- svelte-ignore a11y_autofocus -->
    <h2 autofocus tabindex="-1">
      {participant.maskName} ble reddet!
    </h2>
    <p>
      Panelet reddet {participant.maskName} fra å ta av seg masken denne uken.
    </p>
    {#if $showWebGlAnimations && Gong}
      <Gong {participant} />
    {/if}
  </div>
</div>

<style>
  .content {
    width: 100%;
    height: 50%;
    display: flex;
    flex-direction: column;
    margin-top: 3rem;
    padding: 0 0.3rem;
    align-items: center;
    justify-content: flex-end;
  }
  .countdown-wrapper {
    position: fixed;
    top: 5rem;
    right: 1rem;
  }

  .content {
    --reveal-border: var(--purple);
    --heading: var(--purple);
    --reveal-bg: var(--black);
  }

  .rescued-banner {
    transform: rotate(-5deg);
    left: -2rem;
    width: calc(100% + 4rem);
    text-transform: uppercase;
    max-height: 50%;
    border-top: 3.5px solid;
    border-top-color: currentcolor;
    border-bottom: 5px solid;
    border-bottom-color: currentcolor;
    border-color: var(--reveal-border);
    background: var(--black);
    font-style: italic;
    font-size: 41px;
    font-weight: 850;
    line-height: 70px;
    text-align: center;
  }

  .rescued-banner::after {
    content: '';
    display: block;
    width: 130%;
    left: -15%;
    height: var(--participant-page-height);
    background: var(--reveal-bg);
    position: absolute;
    margin-top: 3.5px;
  }

  .info {
    position: fixed;
    bottom: 5%;
    padding: 0 1rem;
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    margin-inline: 1rem;
    max-width: var(--content-width);
    height: 40%;
    user-select: none;
  }
  p,
  h2 {
    align-self: flex-start;
  }
  h2 {
    font-size: 1rem;
    margin-bottom: 0.2rem;
    margin-top: 2.5rem;
    overflow: hidden;
    color: var(--heading);
    filter: brightness(1.2);
    max-width: 18rem;
    text-overflow: ellipsis;
  }
</style>
