<script lang="ts">
  /* eslint svelte/no-at-html-tags: "off" */
  import { nrkChevronDown } from '@nrk/core-icons';
  import * as Sentry from '@sentry/svelte';
  import { onMount } from 'svelte';
  import {
    maskedParticipants,
    unmaskedParticipants,
    workData,
  } from '../api/api';
  import Countdown from '../lib/components/Countdown/Countdown.svelte';
  import LocationButton from '../lib/components/Location/LocationButton.svelte';
  import LoaderLogo from '../lib/components/Logo/LoaderLogo.svelte';
  import ParticipantListItem from '../lib/components/Participant/ParticipantListItem.svelte';
  import RescuedParticipantListItem from '../lib/components/Participant/RescuedParticipantListItem.svelte';
  import UnmaskedParticipantListItem from '../lib/components/Participant/UnmaskedParticipantListItem.svelte';
  import Scoreboard from '../lib/components/Scoreboard/Scoreboard.svelte';
  import type ListBackground from '../lib/components/Three/ListBackground.svelte';
  import { showWebGlAnimations } from '../lib/util/showwebglAnimations';
  import { trackEvent } from '../lib/util/snowplow';

  let ready = $derived($workData.participants.length > 0);
  let ThreeBackground: typeof ListBackground | null = $state(null);

  onMount(async () => {
    try {
      const module = await import(
        '../lib/components/Three/ListBackground.svelte'
      );
      ThreeBackground = module.default;
    } catch (error) {
      trackEvent({ action: 'error:load-list-background' });
      Sentry.captureException(error);
    }
  });
</script>

<div class="main-page">
  {#if $showWebGlAnimations && ThreeBackground}
    <ThreeBackground />
  {/if}
  <LoaderLogo loading={!ready} text="Henter maskene..." />

  {#if ready}
    <h1>Hvem skjuler seg bak maskene?</h1>
    {#if $maskedParticipants.length === 0}
      <p>Årets sesong er over. Hvor mange riktige klarte du?</p>
    {:else}
      <p>
        Gjett hvem og velg din favoritt. Konkurrer i sofaen eller mot hele Norge
        og vinn premier!
      </p>
    {/if}
    <div class="masked-participants">
      {#each $maskedParticipants as participant}
        <ParticipantListItem {participant} />
      {/each}
    </div>
    {#if $unmaskedParticipants.find((p) => !p.unmasked && !p.rescued)}
      <div class="glow-shadow">
        <a
          class="pin-fancy-button"
          aria-hidden="true"
          tabindex="-1"
          href="#participants-revealed"
        >
          Se hvem som er avslørt!
        </a>
        {@html nrkChevronDown}
      </div>
    {/if}
    {#if $maskedParticipants.length > 0}
      <div class="header-wrapper">
        <div class="line"></div>
        <h2>Resultater</h2>
        <div class="line"></div>
      </div>
      <p id="participants-revealed">
        Her kan du sjekke svarene dine i gjetteleken og se hvordan du ligger an
        i konkurransen.
      </p>
    {/if}
    <Scoreboard />
    <div class="unmasked-participants">
      {#each $unmaskedParticipants as participant, index}
        {@const episodeNumber = Math.min(
          $unmaskedParticipants.length - index,
          6
        )}

        {#if episodeNumber !== 6 || index === 0}
          <h3>{`Episode ${episodeNumber}`}</h3>
        {/if}

        {#if participant.rescued}
          <RescuedParticipantListItem {participant} />
        {:else}
          <UnmaskedParticipantListItem {participant} />
        {/if}
      {/each}
    </div>
    <LocationButton />
  {/if}
</div>
<Countdown />

<style>
  .main-page {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-top: 6rem;
    padding: 6rem 1rem 0;
  }

  h1 {
    margin: 1rem 4rem;
  }

  p {
    text-align: center;
    margin-bottom: 2rem;
  }

  h3 {
    margin-top: 3rem;
    font-weight: 700;
    font-size: 0.875rem;
    line-height: 1.25rem;
    letter-spacing: 0.05em;
    text-align: center;
  }

  .masked-participants,
  .unmasked-participants {
    max-width: var(--content-width);
    width: 100%;
  }

  .header-wrapper {
    display: flex;
    width: 100%;
    max-width: var(--content-width);
    margin-top: 2rem;
    align-items: center;
    gap: 1rem;
  }

  .line {
    height: 1px;
    background: white;
    width: 100%;
    margin-top: 1rem;
  }

  .glow-shadow {
    margin-top: 2rem;
    display: flex;
    flex-direction: column;
    align-items: center;
    filter: drop-shadow(0px 0px 18px var(--red));
    color: red;
    position: sticky;
    bottom: 0.5rem;
    text-transform: uppercase;
  }

  h2 {
    margin: 2rem 0 1rem;
    --corner-size: 0.75rem;
    clip-path: polygon(
      0 0,
      calc(100% - var(--corner-size)) 0,
      100% var(--corner-size),
      100% 100%,
      var(--corner-size) 100%,
      0 calc(100% - var(--corner-size))
    );
    padding-left: var(--corner-size);
    padding-right: var(--corner-size);
    background-color: var(--white);
    color: var(--black);
    letter-spacing: 0.05em;
    font-weight: 700;
    font-size: 0.875rem;
    line-height: 1.75rem;
    text-transform: uppercase;
    text-align: center;
    width: 9rem;
  }
</style>
