export class HttpError extends Error {
  statusCode: number;
  response: Response;

  constructor(response: Response) {
    // Pass remaining arguments (including vendor specific ones) to parent constructor
    super(
      `${response.status} for '${response.url}' with message: '${response.statusText}'`
    );

    this.stack = new Error().stack;
    this.name = 'HttpError';
    this.statusCode = response.status;
    this.response = response;
  }
}
