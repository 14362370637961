<script lang="ts">
  import { onMount } from 'svelte';
  import { unmaskedParticipants } from '../../../api/api';
  import { statistics } from '../../../api/statistics.svelte';

  const allRevealedAreUnmasked = $derived(
    !$unmaskedParticipants.some(
      (participant) => participant.correct && !participant.unmasked
    )
  );

  const percentage = $derived(statistics.percentage);
  const shouldShowStatistics = $derived(
    (allRevealedAreUnmasked &&
      percentage &&
      percentage?.betterThanPercent > 50) ??
      false
  );
  const betterThan99 = $derived(
    percentage?.betterThanPercent != null && percentage.betterThanPercent >= 99
  );

  onMount(() => {
    if ($unmaskedParticipants.length > 0) {
      statistics.fetchPercentage();
    }
  });
</script>

{#if shouldShowStatistics}
  <div class="statistics-shadow" class:betterThan99>
    <div class="statistics" style="--corner-size: 0.75rem;">
      Du er topp
      <b>{Math.max(100 - (percentage?.betterThanPercent ?? 0), 1)}%</b>
      i {percentage?.region}
    </div>
  </div>
{/if}

<style>
  .statistics-shadow {
    filter: drop-shadow(0px 4px 10px var(--purple));
  }

  .statistics-shadow.betterThan99 {
    filter: drop-shadow(0px 4px 10px var(--green));
  }
  .statistics {
    clip-path: polygon(
      0 0,
      calc(100% - var(--corner-size)) 0,
      100% var(--corner-size),
      100% 100%,
      var(--corner-size) 100%,
      0 calc(100% - var(--corner-size))
    );
    padding-left: var(--corner-size);
    padding-right: var(--corner-size);
    background-color: var(--purple);

    padding: 0.2rem 1rem;
  }

  .betterThan99 .statistics {
    background-color: var(--green);
    color: var(--black);
  }
</style>
