import { getRedirectLoginClient } from '@nrk/innlogging-web/redirect-login-client';
import { consentVersion } from './consentVersion.js';

const loginClient = getRedirectLoginClient({});

/**
 * FIXME: (2024) the redirect incorrectly appends &identity= behind the #
 * This is a workaround to always redirect to the frontpage.
 */
const returnUrl = () => {
  const url = new URL(window.location.href);
  url.hash = '';
  return url.toString();
};
export const loginModel = {
  loginTestUser: (_username: string) => {
    // noop in prod
  },
  toggleTestUserConsent: async (_sub: string, _requirement: string) => {
    // noop in prod
  },
  login: () =>
    loginClient.login({
      nrkCtx: 'maskorama',
      returnUrl: returnUrl(),
      requirements: [consentVersion],
    }),
  ensureRequirements: () =>
    loginClient.ensureUserConformsToRequirements({
      requirements: [consentVersion],
      returnUrl: returnUrl(),
    }),
  logout: () =>
    loginClient.logout({
      returnUrl: returnUrl(),
    }),
  goToUserPage: () =>
    loginClient.navigateTo({
      target: 'userpage',
      // @ts-expect-error returnUrl is string
      returnUrl: returnUrl(),
    }),
  forceRefresh: async () => {
    try {
      return await loginClient.forceRefresh();
    } catch {
      //noop
    }
  },
};
