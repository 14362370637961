import { writable } from 'svelte/store';

type ModalContent =
  | 'info'
  | 'thanks'
  | 'locked'
  | 'location'
  | 'feedback'
  | 'consent'
  | undefined;
export const showModal = writable<ModalContent>(undefined);

export const previousFocusElement = writable<HTMLElement | null>(null);
