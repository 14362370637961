<script lang="ts">
  import { onMount } from 'svelte';
  import { link } from 'svelte-spa-router';
  import Smiles from '../Scoreboard/Smiles.svelte';
  import { getFocusId, previousFocus } from './previousFocusElement.svelte';
  import { isListImage } from './typeguard';

  let { participant }: { participant: Maskorama.Participant } = $props();
  let linkElement: HTMLAnchorElement | undefined;

  let listimage = $derived(
    participant.mediaSet.find((ms) => ms.type === 'list_image')
  );
  let guessed = $derived(participant.guessedName?.length ?? 0 > 0);
  let correct = $derived(participant.correct && participant.unmasked);
  let wrong = $derived(
    participant.unmasked && guessed && participant.correct === false
  );
  let positions: Array<{ x: number; y: number }> = $state([]);
  let focusId = $derived(getFocusId(participant.id));

  onMount(() => {
    if (previousFocus.id == focusId) {
      linkElement?.focus();
      previousFocus.removeFocusId();
    }
    const total = 20;
    const { width, height } = linkElement?.getBoundingClientRect() || {
      width: 500,
      height: 160,
    };
    for (let i = 0; i < total; i++) {
      positions.push({
        x: Math.random() * width - width / 2,
        y: Math.random() * height - height / 2,
      });
    }
  });
</script>

<a
  class="participant-wrapper"
  id={`#participant-${participant.id}`}
  bind:this={linkElement}
  use:link
  href="/participant/{participant.id}"
  onclick={(_e) => {
    previousFocus.setFocusId(focusId);
  }}
>
  {#if correct || wrong}
    <div class="smile-wrapper">
      <div class="smile">
        <Smiles {participant} />
      </div>
      <div class="smile-text" class:wrong class:correct>
        {correct ? 'Jippi!' : 'Ai, ai, ai'}
      </div>
    </div>
  {/if}
  <div
    class="participant-box-border"
    class:guessed
    class:notguessed={!guessed}
    class:masked={participant.unmasked === false}
    class:correct
    class:wrong
  >
    <div class="participant-content">
      <h2>
        {`${participant.maskName}${participant.unmasked ? ` var ${participant.realName}` : ''}`}
      </h2>

      <p>
        {#if participant.unmasked}
          {#if wrong}
            Du gjettet <strong>{participant.guessedName}</strong>
          {:else if correct}
            Du gjettet riktig!
          {:else}
            Du gjettet ikke
          {/if}
        {:else}
          <span class="masked-text"> Sjekk om du gjettet riktig </span>
        {/if}
      </p>
      {#if participant.unmasked === false}
        <div class="masks">
          {#each positions as pos, i}
            <span
              class="questionmark"
              style={`--x: ${pos.x}px; --y:${pos.y}px; --delay: ${i * 500}ms`}
            >
              <svg
                height="82"
                width="164"
                viewBox="-12 0 70 32"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <g transform="translate(-6, 0)">
                  <path
                    fill-rule="evenodd"
                    clip-rule="evenodd"
                    d="M25.8477 8.78431L0.630431 0L7.56518 15.0588L0 18.1961L4.41302 25.7255H15.1304L17.0216 29.4902L28.9998 32L40.9784 29.4902L42.8697 25.7255H53.587L58 18.1961L50.4348 15.0588L57.3696 1.32593e-05L32.1523 8.78433L29.0028 5.02367L25.8477 8.78431ZM36.636 16.1685L47.1228 17.8429L45.2722 20.0754L33.6638 20.6336L36.636 16.1685ZM12.7276 17.8429L23.2144 16.1685L26.1866 20.6336L14.5782 20.0754L12.7276 17.8429Z"
                    fill="transparent"
                    stroke="black"
                    stroke-width="0.75"
                    pathLength="100"
                  />
                </g>
              </svg>
            </span>
          {/each}
        </div>
      {/if}
    </div>
  </div>
  <img
    src={listimage && isListImage(listimage) ? listimage.href : ''}
    alt={participant.maskName}
  />
</a>

<style>
  .participant-wrapper {
    display: block;
    position: relative;
    margin: 1.5rem 0;
    transition: transform 0.2s;
  }

  .participant-wrapper:hover,
  .participant-wrapper:focus {
    transform: scale(1.05);
  }

  .smile-wrapper {
    position: absolute;
    display: flex;
    align-items: center;
    left: -0.5rem;
    top: -0.5rem;
    text-transform: uppercase;
    font-size: 0.8rem;
  }
  .smile {
    z-index: 2;
    transform: rotate(-25deg);
  }

  .smile-text {
    z-index: 1;
    color: var(--black);
    white-space: nowrap;
    padding: 0.2rem 1rem 0.2rem 1.5rem;
    margin-left: -1rem;
    margin-top: -0.2rem;
    text-align: center;
    --corner-size: 0.5rem;
    text-decoration: none;
    clip-path: polygon(
      0 0,
      calc(100% - var(--corner-size)) 0,
      100% var(--corner-size),
      100% 100%,
      var(--corner-size) 100%,
      0 calc(100% - var(--corner-size))
    );
  }

  .smile-text.correct {
    background: var(--green);
  }
  .smile-text.wrong {
    background: var(--orange);
  }

  .participant-box-border {
    --border-size: 0.2rem;
    --right-top-corner-size: 7.5rem;
    --left-bottom-corner-size: 0.75rem;
    --background-color-size: calc(
      var(--left-bottom-corner-size) + var(--border-size) +
        (var(--border-size) / 2)
    );
    text-decoration: none;
    --bg-border-color: var(--black);
    /* background: transparent; */
    background: linear-gradient(
      90deg,
      var(--bg-border-color) 0%,
      var(--bg-border-color) var(--background-color-size),
      transparent var(--background-color-size),
      transparent 100%
    );
    clip-path: polygon(
      0 0,
      calc(100% - var(--right-top-corner-size)) 0,
      100% var(--right-top-corner-size),
      100% 100%,
      var(--left-bottom-corner-size) 100%,
      0 calc(100% - var(--left-bottom-corner-size))
    );
  }

  .participant-box-border.wrong {
    --bg-border-color: var(--orange);
  }

  .participant-box-border.correct {
    --bg-border-color: var(--green);
  }

  .participant-box-border.masked {
    --bg-border-color: transparent;
  }

  .participant-content {
    --zero-position: calc(0% + var(--border-size));
    --hundred-position: calc(100% - var(--border-size));
    --half-border: calc(var(--border-size) / 2);
    --gradient-color: var(--black);
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 0.5rem;
    min-height: 8.5rem;
    max-width: 100%;
    padding: 1rem 1.5rem;
    background: linear-gradient(
      70deg,
      var(--gradient-color) -30%,
      var(--black) 40%
    );
    color: white;
    clip-path: polygon(
      var(--zero-position) 0,
      100% 0,
      100% 100%,
      calc(var(--left-bottom-corner-size) + (var(--border-size) * 1.5)) 100%,
      calc(var(--left-bottom-corner-size))
        calc(var(--hundred-position) - var(--half-border)),
      var(--zero-position)
        calc(
          var(--hundred-position) -
            (var(--left-bottom-corner-size) - var(--half-border))
        )
    );
  }

  .participant-content > p {
    /* this is how to make text ellipsis that spans multiple lines 
        works cross browser even if it is a bit heavy webkitty in
        propnames */
    max-width: 68%;
    display: -webkit-box;
    overflow: hidden;
    -webkit-line-clamp: 2; /* number of lines to show */
    line-clamp: 2;
    -webkit-box-orient: vertical;
  }

  .masked .participant-content {
    background: linear-gradient(0deg, var(--red) 0%, var(--purple) 100%);
  }

  .correct .participant-content {
    --gradient-color: var(--green);
  }

  .wrong .participant-content {
    --gradient-color: var(--orange);
  }

  .masked-text {
    z-index: 2;
    position: relative;
  }

  h2 {
    /* 100% - width of image */
    max-width: calc(100% - 115px);
    margin-top: 0.5rem;
    font-size: 1rem;
    font-weight: 600;
    color: var(--red);
  }

  .masked h2 {
    color: var(--white);
  }

  .correct h2 {
    color: var(--green);
  }

  .wrong h2 {
    color: var(--orange);
  }

  p {
    font-size: 1.25rem;
  }

  .masked p {
    color: var(--white);
    font-size: 0.875rem;
    font-weight: 700;
    margin-top: 0.25rem;
    text-transform: uppercase;
    letter-spacing: 0.05em;
  }

  img {
    position: absolute;
    right: 0;
    bottom: 0;
    z-index: 1;
    width: 8rem;
  }

  svg {
    transform: scale(0.4);
  }

  .masks {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    overflow: hidden;
  }
  .questionmark {
    position: absolute;
    opacity: 0;
    z-index: 1;
    transform: translate(var(--x), var(--y));
    animation: fadeIn 12s infinite ease-in-out;
    animation-delay: var(--delay, 0ms);
  }
  @keyframes fadeIn {
    0% {
      opacity: 0;
    }
    10% {
      opacity: 0.4;
    }
    20% {
      opacity: 0;
    }
    100% {
      opacity: 0;
    }
  }
</style>
