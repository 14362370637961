<script lang="ts">
  /* eslint svelte/no-at-html-tags: "off" */
  import { nrkSpinner } from '@nrk/core-icons';

  export let onClick = () => {};
  export let sending = false;
  export const focus = () => {
    button?.focus();
  };
  let button: HTMLButtonElement;
</script>

<button
  on:click={onClick}
  class="send-button"
  aria-label="Send inn"
  type="submit"
  bind:this={button}
  disabled={sending}
>
  {#if !sending}
    <svg
      aria-hidden="true"
      class="send-button__icon"
      viewBox="0 0 19 18"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M16.6661 10.7445C18.548 9.87524 18.548 7.20043 16.6661 6.33122L3.44982 0.22687C1.83918 -0.517053 0 0.659344 0 2.43349V5.70047C0 6.52698 0.622041 7.22103 1.44361 7.31121L9.31896 8.17566C9.54734 8.19581 9.72249 8.3871 9.72249 8.61637C9.72249 8.84564 9.54734 9.03693 9.31896 9.05708L1.44361 9.92152C0.62204 10.0117 0 10.7058 0 11.5323V14.6422C0 16.4163 1.83918 17.5927 3.44982 16.8488L16.6661 10.7445Z"
      />
    </svg>
  {:else}
    <div class="spinner">
      {@html nrkSpinner}
    </div>
  {/if}
</button>

<style>
  .send-button {
    display: inline-flex;
    align-items: center;
  }

  .send-button__icon {
    width: 1.5rem;
    height: 1.5rem;
    fill: var(--red);
  }

  .spinner {
    animation: spin 1s infinite linear;
    transform-origin: center;
    display: grid;
    place-content: center;
  }

  @keyframes spin {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
</style>
