// <!-- nothing
// didnt guess
// guessed wrong
// guess right
// hasnt revealed -->

export const participantState = (participant: Maskorama.Participant) => {
  if (!participant.realName) return;
  if (!participant.unmasked) return 'masked';
  if (!participant.guessedName) return 'empty';
  if (participant.correct) return 'correct';
  return 'wrong';
};
