<script lang="ts">
  const year = new Date().getFullYear();
</script>

<footer>
  <div class="footer-content">
    <a href="https://www.nrk.no/" class="nrkno-link">
      <span class="sr-only">NRK</span>
      <svg
        viewBox="0 0 35 14"
        width="3.500em"
        height="1.400em"
        aria-hidden="true"
        focusable="false"
        fill="white"
      >
        <path
          class="logo"
          d="M31.0878023 7.55918018c-.2517074-.42642875-.2661333-.68861697-.02338-1.10029556L34.399462 1h-4.6267395s-2.7797248 4.54205453-3.1597731 5.17316245c-.3764003.63027925-.3601505 1.02273292.0154208 1.67041405C27.0084185 8.49042897 29.7727225 13 29.7727225 13h4.6267395s-3.2751804-5.37353258-3.3116597-5.44081982M21.5941377 13h4.2077247V1h-4.2077247M18.9405537.77010873c-1.3163763 0-2.3817762 1.07539896-2.3817762 2.40310754 0 1.32687533 1.0653999 2.4056073 2.3817762 2.4056073 1.3185428 0 2.3884423-1.07873197 2.3884423-2.4056073 0-1.32770858-1.0698995-2.40310754-2.3884423-2.40310754M12.0635332 13h4.2052375V1h-4.2052375M0 13h4.19744416V1H0M9.50872938 2.92796177C9.29333553 1.82832914 8.33144198 1 7.17852938 1H4.47507953l2.71654925 12h4.56969912L9.50872938 2.92796177z"
        />
      </svg>
    </a>
    <div class="text">
      <a
        href="https://www.nrk.no/etikk/opphavsrett-pa-nrk.no-1.2843522"
        class="rights">Opphavsrett NRK © {year}</a
      >
      <a
        href="https://www.nrk.no/etikk/redaktoransvar-pa-nrk.no-1.2843526"
        class="director"
      >
        <div>Ansvarlig redaktør: Vibeke Fürst Haugen</div>
        <div>Nettsjef: Hildegunn Soldal</div>
      </a>
    </div>
  </div>
</footer>

<style>
  footer {
    width: 100%;
    padding: 2rem;
    text-align: center;
    position: relative;
    display: grid;
    margin-top: 5rem;
  }

  .footer-content {
    grid-row: 1;
    grid-column: 1;
  }

  .nrkno-link {
    margin-left: auto;
    margin-right: auto;
    width: fit-content;
    padding: 1rem 0;
  }

  .rights {
    font-weight: 600;
  }

  .director {
    margin-top: 1rem;
    display: flex;
    justify-content: center;
    flex-direction: column;
    flex-wrap: wrap;
    font-weight: 290;
  }

  .director div {
    padding: 0.25rem;
  }

  a {
    color: inherit;
    display: block;
    text-decoration: none;
  }
</style>
