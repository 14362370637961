<script lang="ts">
  import { workData } from '../../../api/api';
  import { roundPaused } from '../Modal/locked-state.svelte';
  import { countdown } from './countdown-utils.svelte';

  let { largefixed = true } = $props();

  const getText = (timeLeft: number) => {
    const allSec = Math.max(timeLeft / 1000, 0);

    const min = Math.floor(allSec / 60);
    const sec = Math.floor(allSec % 60);
    const secStr = sec > 9 ? sec : `0${sec}`;

    return `${min}:${secStr}`;
  };
  let countDownText = $derived(getText(countdown.timeLeft));
  let size = $derived(largefixed ? 85 : 65);

  const getScreenReadText = (timeLeft: number) => {
    const allSec = Math.max(timeLeft / 1000, 0);

    const min = Math.floor(allSec / 60);
    const sec = Math.floor(allSec % 60);
    // If less than 30 seconds remain
    if (min === 0 && sec < 30) {
      return `Det er noen få sekunder igjen av gjettingen`;
    }

    // If a full minute passed
    if (sec === 0) {
      if (min === 0) {
        return `Gjettingen er satt på pause`;
      }
      if (min === 1) {
        return `Det er ${min} minutt igjen av gjettingen`;
      }
      return `Det er ${min} minutter igjen av gjettingen`;
    }
    return null;
  };
  let screenReaderText = $derived(getScreenReadText(countdown.timeLeft));

  const getCirclePath = (percent: number) => {
    let invertedPercentage = Math.max((100 - percent * 100) / 100, 0.0);

    const startX = Math.cos(2 * Math.PI * 0);
    const startY = Math.sin(2 * Math.PI * 0);
    const x = Math.cos(2 * Math.PI * invertedPercentage);
    const y = Math.sin(2 * Math.PI * invertedPercentage);
    const largeArcFlag = invertedPercentage > 0.5 ? 1 : 0;

    return `M ${startX} ${startY}
            A 1 1 0 ${largeArcFlag} 1 ${x} ${y}
            L 0 0`;
  };

  let circlePath = $derived(getCirclePath(countdown.percentage));
</script>

{#if $workData.round?.endTime != null && $roundPaused === 'not_paused'}
  <div
    aria-hidden="true"
    class="countdown"
    class:largefixed
    style={`--size: ${size}px;`}
  >
    <svg width={size} height={size} viewBox="-1 -1 2 2">
      <path d={circlePath} />
      <defs>
        <linearGradient id="gradient" x1="0%" y1="0%" x2="100%" y2="100%">
          <stop offset="0%" stop-color="#f5255b" />
          <stop offset="100%" stop-color="#843fae" />
        </linearGradient>
      </defs>
    </svg>
    <div class="count-text">
      {countDownText}
    </div>
  </div>
  <div class="sr-only" aria-live="polite">
    {screenReaderText != null ? screenReaderText : ''}
  </div>
{/if}

<style>
  .countdown {
    display: grid;
    position: relative;
    filter: drop-shadow(0px 0px 12px var(--black));
    z-index: 1;
    user-select: none;
  }

  .countdown.largefixed {
    position: fixed;
    bottom: 1rem;
    left: 1rem;
  }

  .countdown > * {
    grid-row: 1;
    grid-column: 1;
  }

  .count-text {
    display: grid;
    place-items: center;
    width: var(--size);
    height: var(--size);
    transform: scale(0.9);
    border-radius: 50%;
    font-size: 1.35rem;
    font-weight: 700;
    filter: drop-shadow(0px 0px 2px var(--black));
    font-feature-settings: 'tnum';
    background-color: var(--black);
  }

  svg {
    transform: rotate(-0.25turn);
    filter: drop-shadow(0px 0px 2px var(--red)) brightness(1.2);
  }

  path {
    fill: url(#gradient);
  }
</style>
