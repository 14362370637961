<script>
  /* eslint svelte/no-at-html-tags: "off" */
  import { nrkInfo } from '@nrk/core-icons';
  import { showModal } from '../../util/stores/modalStore';
</script>

<button
  on:click={() => showModal.set('info')}
  title="Informasjon om gjetteleken"
>
  <span>
    {@html nrkInfo}
  </span>
</button>

<style>
  button {
    height: 44px;
    width: 44px;
    background: #f5255b;
    color: black;
    border-radius: 999px;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  span {
    height: 24px;
    width: 24px;
  }
</style>
