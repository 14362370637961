<script lang="ts">
  /* eslint svelte/no-at-html-tags: "off" */
  import { nrkMediaPause, nrkMediaPlay } from '@nrk/core-icons';
  import { onMount } from 'svelte';
  import { checkAutoplay } from '../../util/autoplay';
  import { isDanceText, isMovingStill, isMovingStillPoster } from './typeguard';
  import * as Sentry from '@sentry/svelte';

  export let participant: Maskorama.Participant;
  $: video = participant?.mediaSet.find((ms) => ms.type === 'moving_still');
  $: videoSrc = isMovingStill(video) ? video.properties.large : '';
  $: videoAlt = participant?.mediaSet.find((ms) => ms.type === 'dance_text');
  $: videoAltText = isDanceText(videoAlt) ? videoAlt.value : '';

  $: videoPoster = participant.mediaSet.find(
    (ms) => ms.type === 'moving_still_poster'
  );
  $: videoPosterSrc = isMovingStillPoster(videoPoster)
    ? videoPoster.properties.large
    : '';

  let showPosterStill = true;
  let videoIsPlaying = false;
  let shouldAutoPlay = false;
  let videoElement: HTMLVideoElement | null;

  onMount(() => {
    shouldAutoPlay = checkAutoplay();
    const handlePlaying = () => {
      if (videoElement != null) {
        videoElement.currentTime = 0;
        videoIsPlaying = true;
        showPosterStill = false;
      }
    };

    if (videoElement != null) {
      videoElement.addEventListener('playing', handlePlaying, { once: true });
      if (shouldAutoPlay) {
        videoElement.play().catch((error) => {
          shouldAutoPlay = false;
          Sentry.captureException(error);
        });
      }
    }
    return () => {
      videoElement?.removeEventListener('playing', handlePlaying);
    };
  });
</script>

<div class="background" aria-label={videoAltText} role="img">
  <figure aria-hidden="true">
    <div class="image-wrapper" style="--bgurl: url({videoPosterSrc})">
      <video
        bind:this={videoElement}
        style="--opacity: {showPosterStill ? 0 : 1};"
        preload="metadata"
        poster={videoPosterSrc}
        muted
        playsinline
        loop
        disableremoteplayback
        x-webkit-airplay="deny"
        autoplay={shouldAutoPlay}
        src={videoSrc}
      >
      </video>
    </div>
  </figure>
</div>
{#if !shouldAutoPlay}
  <button
    class="play-button"
    onclick={() => {
      if (videoIsPlaying) {
        videoElement?.pause();
        videoIsPlaying = false;
      } else {
        videoElement?.play();
        shouldAutoPlay = checkAutoplay();
        videoIsPlaying = true;
      }
    }}
    aria-label={videoIsPlaying ? 'Stop video' : 'Start video'}
  >
    {@html videoIsPlaying ? nrkMediaPause : nrkMediaPlay}
  </button>
{/if}

<style>
  .background {
    z-index: -1;
    position: absolute;
    top: 0;
    left: 0;
    /* Since there can be rounding issues on our 100vh-hack 
    for full height participant-page, the background is set to bottom: -1px  */
    bottom: -1px;
    right: 0;
    transform: translateZ(0);
  }

  .image-wrapper {
    display: block;
    background-image: var(--bgurl);
    background-position:
      center center,
      center center;
    transform: translateZ(0);
    background-size: cover;
  }

  .image-wrapper,
  .background video,
  .background figure {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }

  video {
    display: block;
    object-fit: cover;
    opacity: var(--opacity);
    transform: translateZ(0);
    transition: opacity 50ms;
  }

  button {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    font-size: 1.875rem;
    line-height: 2.25rem;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    border-radius: 50%;
    padding: 1.5rem;
    animation: none;
  }
</style>
