<script lang="ts">
  import * as Sentry from '@sentry/svelte';
  import { onMount } from 'svelte';
  import PrimaryButton from '../lib/components/Buttons/PrimaryButton.svelte';
  import BlinkWrapper from '../lib/components/HeadingBlinkWrapper.svelte';
  import { loginModel } from '../lib/components/Login/loginModel';
  import { loginClient } from '../lib/components/Login/loginSessionStore';
  import { trackEvent, trackPageView } from '../lib/util/snowplow';
  import { getItem, setItem } from '../lib/util/storage';

  const login = async () => {
    trackEvent({ action: 'click:intro-goto-login' });
    try {
      loginModel.login();
    } catch (error) {
      const cookies = document.cookie.split('; ');
      const isOffgridInBackend = cookies.some(
        (cookie) => cookie === 'login_offgrid=true'
      );
      if (isOffgridInBackend) {
        trackEvent({ action: 'error:login.offgrid-backend' });
      }

      Sentry.setTag('offgrid', 'login');
      Sentry.captureException(error);
      trackEvent({ action: 'error:login.offgrid' });
      const lastForceRefresh = getItem<string>('local', 'lastForceRefresh');
      const now = Date.now();

      if (!lastForceRefresh || now - parseInt(lastForceRefresh, 10) > 10_000) {
        await loginClient.forceRefresh();
        setItem('local', 'lastForceRefresh', now.toString());
      }
    }
  };

  onMount(() => {
    trackPageView('intro');
  });
</script>

<div class="intro-page">
  <div class="bg"></div>
  <BlinkWrapper>Velkommen til Norges sprøeste gjettelek</BlinkWrapper>
  <p>
    Skjerp øra og bruk magefølelsen, bli med på leken og gjett hvilke kjendiser
    vi har maskert.
  </p>
  <p>Konkurrer i sofaen eller mot hele landet.</p>
  <p>En ny kjendis avsløres hver uke.</p>
  <PrimaryButton onClick={login} blink={true}>Start gjetteleken</PrimaryButton>
</div>

<style>
  .intro-page {
    display: flex;
    padding: 12rem 0 16rem;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 2rem;
  }

  .bg {
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: -1;
    background-image: url('../assets/bg-desk.jpg');
    background-position: bottom center;
    background-size: cover;
    background-repeat: no-repeat;
  }

  @media (max-width: 1024px) {
    .bg {
      background-image: url('../assets/bakgrunn_mobil.jpg');
    }
  }

  p {
    max-width: 20rem;
    text-align: center;
  }
</style>
