import { getItem, removeItem, setItem } from '../../util/storage';

export const getFocusId = (participantId: Maskorama.Participant['id']) => {
  return `participant-${participantId}-link`;
};

const createpreviousFocusIdState = () => {
  let focusid = $state<string | undefined>(
    getItem<string>('session', 'list-focus-id')
  );

  return {
    get id() {
      return focusid;
    },
    setFocusId: (id: string) => {
      focusid = id;
      setItem<string>('session', 'list-focus-id', focusid);
    },
    removeFocusId: () => {
      focusid = undefined;
      removeItem('session', 'list-focus-id');
    },
  };
};

export const previousFocus = createpreviousFocusIdState();
