<script lang="ts">
  import { participantState } from '../../util/participantState';
  import Frown from './SVGs/Frown.svelte';
  import Questionmark from './SVGs/Questionmark.svelte';
  import Smile from './SVGs/Smile.svelte';

  export let participant;
  let state = participantState(participant);
</script>

{#if state === 'masked'}
  <div>
    <Questionmark />
  </div>
{:else if state === 'correct'}
  <div>
    <Smile />
  </div>
{:else if state === 'wrong'}
  <div>
    <Frown />
  </div>
{:else if state === 'empty'}
  <div class="no-guess"></div>
{:else}
  <div></div>
{/if}

<style>
  div {
    height: 32px;
    width: 32px;
    min-width: 32px;
    background: #24004f;
    border-radius: 50%;
  }

  .no-guess {
    border: 3px solid var(--purple);
  }
</style>
