<script lang="ts">
  /* eslint svelte/no-at-html-tags: "off" */
  import { nrkClose } from '@nrk/core-icons';
  import { onDestroy } from 'svelte';
  import { setMyGuess } from '../../../api/api';
  import { checkSpelling } from '../../../api/checkspelling';
  import { trackEvent } from '../../util/snowplow';
  import * as TEXTS from '../../util/texts';
  import SendButton from '../Buttons/SendButton.svelte';
  import Chatbox from './Chatbox/Chatbox.svelte';

  export let participant: Maskorama.Participant;
  export let isLocked: boolean;

  let guess = participant.guessedName || '';
  let submitting = false;
  let sent = false;
  let suggestedName: string | null;
  let timer: number | undefined;
  let chatboxTimer: number | undefined;
  let clickedSuggest = false;
  let showChatbox = false;

  const updateSuggestion = async () => {
    suggestedName = await checkSpelling(guess, participant.id);
    if (suggestedName != null) {
      trackEvent({
        action: 'suggest-presented',
        label: `participant:${participant.id}`,
      });
    }
  };

  const submitGuess = async () => {
    submitting = true;
    guess = guess.trimEnd();
    const guessedName = await setMyGuess(participant.id, guess);
    if (guessedName != null) {
      guess = guessedName;
    }
    updateSuggestion();
    submitting = false;
    sent = true;

    showChatbox = true;
    chatboxTimer = window.setTimeout(() => {
      showChatbox = false;
    }, 3000);

    if (timer) clearTimeout(timer);
    timer = window.setTimeout(() => {
      timer = undefined;
      sent = false;
      clickedSuggest = false;
    }, 3_000);
  };

  const handleSuggest = async (suggestedName: string) => {
    clickedSuggest = true;
    trackEvent({
      action: 'suggest-clicked',
      label: `participant:${participant.id}`,
    });
    guess = suggestedName;
    submitGuess();
  };

  onDestroy(() => {
    if (timer) clearTimeout(timer);
    if (chatboxTimer) clearTimeout(chatboxTimer);
  });

  $: canSubmit = guess !== (participant.guessedName || '');
  $: revealText = showChatbox
    ? TEXTS.getRevealedText(TEXTS.CHATBOX_TYPES.GUESS, 0, participant.mediaSet)
    : '';
</script>

{#if showChatbox}
  <div class="chatbox-wrapper">
    <Chatbox>
      {revealText}
    </Chatbox>
  </div>
{/if}
<div class="guess-box">
  <h2>Hvem er {participant.maskName}?</h2>
  <div class="input-wrapper">
    <input
      type="text"
      disabled={submitting || isLocked}
      placeholder="Gjett her"
      spellcheck="false"
      on:keyup={(e) => {
        if (e.key === 'Enter' && canSubmit) {
          submitGuess();
        }
      }}
      maxlength="100"
      bind:value={guess}
      aria-labelledby={`participant-id-${participant.id}`}
    />
    <div class="send-button">
      {#if canSubmit}
        <SendButton
          onClick={() => {
            submitGuess();
            if (guess === '') {
              trackEvent({
                action: 'remove-guess',
                label: `participant:${participant.id}-sendbutton`,
              });
            } else {
              trackEvent({
                action: 'add-guess',
                label: `participant:${participant.id}-sendbutton`,
              });
            }
            trackEvent({
              action: 'interaction',
            });
          }}
          sending={submitting}
        />
      {:else if sent}
        <span class="sent-text" aria-live="assertive"> Sendt </span>
      {:else if isLocked}
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="24"
          height="24"
          viewBox="0 0 24 24"
          fill="none"
        >
          <path
            d="M8 7C8 4.79086 9.79086 3 12 3C14.2091 3 16 4.79086 16 7V8H18V7C18 3.68629 15.3137 1 12 1C8.68629 1 6 3.68629 6 7V8H8V7Z"
            fill="white"
          />
          <path
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M21 22V10H3V22H21ZM13 14V19H11V14H13Z"
            fill="white"
          />
        </svg>
      {/if}
    </div>
  </div>
  <div class="suggest-wrapper">
    {#if suggestedName}
      Mente du
      <button
        class="suggested-name"
        on:click={() => handleSuggest(suggestedName!)}
        title={`Trykk for å endre ditt gjett til ${suggestedName}`}
        >{suggestedName}?</button
      >
      <button
        class="reset-suggest"
        on:click={() => {
          suggestedName = null;
          trackEvent({
            action: 'suggest-closed',
            label: `participant:${participant.id}`,
          });
        }}>{@html nrkClose}</button
      >
    {:else if sent && clickedSuggest}
      <div class="updated-text">Ditt gjett er oppdatert!</div>
    {/if}
  </div>
</div>

<style>
  .input-wrapper {
    position: relative;
  }

  .chatbox-wrapper {
    width: 300px;
    position: fixed;
    z-index: 99;
    top: 38%;
    left: 50%;
    transform: translate(-50%, -50%);
  }

  h2 {
    font-size: 1rem;
    color: white;
  }

  .guess-box {
    width: 100%;
    background: black;
    padding: 2rem;
    --corner-size: 1.5rem;
    --border-size: 0.125rem;
    --zero-position: calc(0% + var(--border-size));
    --hundred-position: calc(100% - var(--border-size));
    --half-border: calc(var(--border-size) / 2);
    /* Mad scientist calculations to get the correct size of border */
    clip-path: polygon(
      var(--zero-position) var(--zero-position),
      calc(var(--hundred-position) - (var(--corner-size) - var(--half-border)))
        var(--zero-position),
      var(--hundred-position) calc(var(--corner-size) + var(--half-border)),
      var(--hundred-position) var(--hundred-position),
      calc(var(--corner-size) + var(--half-border)) var(--hundred-position),
      var(--zero-position)
        calc(
          var(--hundred-position) - (var(--corner-size) - var(--half-border))
        )
    );
  }

  input {
    width: 88%;
    font-size: 1.5rem;
    border: none;
    border-radius: 0;
    background-color: black;
    color: var(--white);
    margin-top: 1rem;
    padding-inline: 0;
    padding-bottom: 0.5rem;
    border-bottom: 1px solid var(--white);
  }

  input:focus-visible {
    outline: none;
    box-shadow: 0 1px 0 0 var(--white);
  }

  .send-button {
    height: 22px;
    position: absolute;
    right: 0;
    bottom: 0.9rem;
    color: #f5255b;
    text-transform: uppercase;
    font-weight: 800;
  }

  .sent-text {
    position: absolute;
    right: 0;
    bottom: 0rem;
  }
  .suggest-wrapper {
    display: flex;
    align-items: center;
    white-space: pre;
    margin-top: 1rem;
  }

  .suggested-name {
    display: flex;
    align-items: center;
    padding: 0.2rem 0.5rem;
    margin-left: 5px;
    font-weight: 800;
    color: black;
    background: #40e0f2;
  }

  .reset-suggest {
    color: #40e0f2;
    display: grid;
    place-content: center;
  }
  .updated-text {
    color: #40e0f2;
    font-weight: 800;
  }
</style>
