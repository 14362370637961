<script lang="ts">
  /* eslint svelte/no-at-html-tags: "off" */
  import { nrkLockActive, nrkStarActive } from '@nrk/core-icons';
  import { onMount } from 'svelte';
  import { trackEvent } from '../../util/snowplow';
  import { roundPaused } from '../Modal/locked-state.svelte';
  import { getAccessibleText } from './participant-list-item-aria';
  import { getFocusId, previousFocus } from './previousFocusElement.svelte';
  import { isListImage } from './typeguard';

  let { participant }: { participant: Maskorama.Participant } = $props();
  let linkElement: HTMLAnchorElement | undefined;
  let listimage = $derived(
    participant.mediaSet.find((ms) => ms.type === 'list_image')
  );
  let favorite = $derived(participant.favorite);
  let guessed = $derived(participant.guessedName?.length ?? 0 > 0);
  let guessedAndFavorite = $derived(guessed && favorite);
  let locked = $derived($roundPaused === 'paused');
  let focusId = $derived(getFocusId(participant.id));
  let participantPageLink = $derived(
    locked ? '#' : `#/participant/${participant.id}`
  );

  onMount(() => {
    if (previousFocus.id == focusId) {
      linkElement?.focus();
      previousFocus.removeFocusId();
    }
  });
</script>

<a
  class="participant-wrapper"
  href={participantPageLink}
  bind:this={linkElement}
  onclick={(e) => {
    if (locked) {
      e.preventDefault();
      trackEvent({
        action: 'participant-clicked-locked',
        label: `participant:${participant.id}`,
      });
    } else {
      previousFocus.setFocusId(focusId);
    }
  }}
  id={`#participant-${participant.id}`}
  aria-disabled={locked}
  aria-label={getAccessibleText(participant, $roundPaused === 'paused')}
  title={locked ? undefined : 'Trykk for å gå til deltakersiden'}
>
  {#if locked}
    <div class="lock-icon">
      {@html nrkLockActive}
    </div>
  {/if}
  <div
    class="participant-box-border"
    class:favorite
    class:guessed
    class:locked
    class:notguessed={!guessed}
    class:guessedAndFavorite
  >
    <div class="participant-content">
      {#if favorite}
        <span class="favorite-star">
          {@html nrkStarActive}
        </span>
      {/if}
      <p class="maskname">{participant.maskName}</p>
      <p>
        {#if !!participant.guessedName}
          Du gjettet <strong>{participant.guessedName}</strong>
        {:else if $roundPaused === 'paused'}
          Gjetting er låst
        {:else}
          Gjett hvem
        {/if}
      </p>
    </div>
  </div>
  <img
    src={listimage && isListImage(listimage) ? listimage.href : ''}
    alt={participant.maskName}
  />
</a>

<style>
  .participant-wrapper {
    display: block;
    position: relative;
    margin-top: 3rem;
    transition: transform 0.2s;
    z-index: 0;
  }

  .participant-wrapper:hover,
  .participant-wrapper:active {
    transform: scale(1.05);
  }

  .participant-wrapper[aria-disabled='true'] {
    cursor: not-allowed;
  }

  .lock-icon {
    position: absolute;
    display: flex;
    align-items: center;
    left: -0.5rem;
    top: 2rem;
    z-index: 1;
    filter: drop-shadow(0px 0px 2px var(--black))
      drop-shadow(0px 0px 4px var(--black));
    color: var(--red);
    font-size: 0.875rem;
  }

  .participant-box-border {
    --border-size: 0.2rem;
    --right-top-corner-size: 7.5rem;
    --left-bottom-corner-size: 0.75rem;
    --background-color-size: calc(
      var(--left-bottom-corner-size) + var(--border-size) +
        (var(--border-size) / 2)
    );
    --background-angle: 90deg;
    text-decoration: none;
    --bg-border-color: var(--black);
    /* background: transparent; */
    background: linear-gradient(
      var(--background-angle),
      var(--bg-border-color) 0%,
      var(--bg-border-color-alt, var(--bg-border-color))
        var(--background-color-size),
      transparent var(--background-color-size),
      transparent 100%
    );
    clip-path: polygon(
      0 0,
      calc(100% - var(--right-top-corner-size)) 0,
      100% var(--right-top-corner-size),
      100% 100%,
      var(--left-bottom-corner-size) 100%,
      0 calc(100% - var(--left-bottom-corner-size))
    );
  }

  .participant-box-border.locked.notguessed {
    --background-angle: 87deg;
    --bg-border-color-alt: var(--purple);
    --bg-border-color: var(--red);
  }

  .participant-box-border.guessed {
    --bg-border-color: var(--red);
  }

  .participant-box-border.favorite {
    --bg-border-color: var(--cyan);
  }

  .participant-content {
    --zero-position: calc(0% + var(--border-size));
    --hundred-position: calc(100% - var(--border-size));
    --half-border: calc(var(--border-size) / 2);
    --gradient-color: var(--black);
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 0.5rem;
    min-height: 8.5rem;
    max-width: var(--content-width);
    max-width: 100%;
    padding: 1rem 1.5rem;
    background: linear-gradient(
      70deg,
      var(--gradient-color) -30%,
      var(--black) 40%
    );
    color: white;
    clip-path: polygon(
      var(--zero-position) 0,
      100% 0,
      100% 100%,
      calc(var(--left-bottom-corner-size) + (var(--border-size) * 1.5)) 100%,
      calc(var(--left-bottom-corner-size))
        calc(var(--hundred-position) - var(--half-border)),
      var(--zero-position)
        calc(
          var(--hundred-position) -
            (var(--left-bottom-corner-size) - var(--half-border))
        )
    );
  }

  .participant-content > p {
    /* this is how to make text ellipsis that spans multiple lines
        works cross browser even if it is a bit heavy webkitty in
        propnames */
    max-width: 68%;
    display: -webkit-box;
    overflow: hidden;
    -webkit-line-clamp: 2; /* number of lines to show */
    line-clamp: 2;
    -webkit-box-orient: vertical;
  }

  .guessed .participant-content {
    --gradient-color: var(--red);
  }

  .favorite.guessed .participant-content {
    --gradient-color: var(--cyan);
  }

  .favorite-star {
    color: var(--cyan);
    font-size: 0.8rem;
  }

  .maskname,
  .notguessed .maskname {
    margin-top: 0.5rem;
    font-size: 1rem;
    font-weight: 600;
    color: var(--white);
    text-transform: unset;
  }

  .favorite-star ~ .maskname {
    margin-top: 0;
  }

  p {
    font-size: 1.25rem;
  }

  .notguessed p {
    font-size: 0.875rem;
    font-weight: 700;
    margin-top: 0.25rem;
    color: var(--red);
    text-transform: uppercase;
  }

  img {
    position: absolute;
    right: 0;
    bottom: 0;
    z-index: 1;
    width: 8rem;
  }
</style>
