<script lang="ts">
  /* eslint svelte/no-at-html-tags: "off" */
  import { nrkArrowLeft, nrkSpinner } from '@nrk/core-icons';
  import { region } from '../api/location.svelte';
  import PrimaryButton from '../lib/components/Buttons/PrimaryButton.svelte';
  import BlinkWrapper from '../lib/components/HeadingBlinkWrapper.svelte';
  import { loginModel } from '../lib/components/Login/loginModel';
  import { showModal } from '../lib/util/stores/modalStore';
  import { trackEvent, trackPageView } from '../lib/util/snowplow';
  import { onMount } from 'svelte';
  export let isModal = false;
  let heading: HTMLHeadingElement;

  onMount(() => {
    if (!isModal) {
      trackPageView('consentpage');
    }
    if (heading != null) {
      heading.focus();
    }
  });
</script>

<div class="consent-page">
  <div class="bg"></div>
  {#if isModal}
    <button
      class="back-button"
      title="Lukk"
      onclick={() => showModal.set(undefined)}
    >
      <span>
        {@html nrkArrowLeft}
      </span>
    </button>
  {/if}
  <BlinkWrapper bind:heading
    >Velkommen til Norges sprøeste gjettelek</BlinkWrapper
  >
  <p>
    For å sende inn forslag og delta interaktivt i Maskorama må du godkjenne
    tilvalg i brukervilkår.
  </p>
  <p>Dette gjør NRK for å ivareta ditt personvern.</p>
  <PrimaryButton
    onClick={() => {
      trackEvent({ action: 'click:consent-goto-login' });
      loginModel.ensureRequirements();
    }}
    blink={true}>Fortsett</PrimaryButton
  >
  <button
    disabled={region.isSubmitting}
    onclick={async () => {
      await region.setLocation({ optedOut: true, communeNumber: null });
      trackEvent({ action: 'click:consent-optout' });
    }}
    >Spill uten å vinne premier {#if region.isSubmitting}
      <div class="spinner">
        {@html nrkSpinner}
      </div>
    {/if}</button
  >
</div>

<style>
  .consent-page {
    display: flex;
    padding: 12rem 0;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    color: var(--white);
    gap: 2rem;
  }

  .bg {
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: -1;
    background-image: url('../assets/bg-desk.jpg');
    background-position: bottom center;
    background-size: cover;
    background-repeat: no-repeat;
  }

  @media (max-width: 1024px) {
    .bg {
      background-image: url('../assets/bakgrunn_mobil.jpg');
    }
  }

  .back-button {
    position: absolute;
    top: 5rem;
    left: 1.5rem;
    color: black;
    background-color: var(--red);
    border-radius: 9999px;
    width: 2.5rem;
    height: 2.5rem;
    text-decoration: none;
    display: grid;
    place-items: center;
  }

  p {
    max-width: 20rem;
    text-align: center;
  }
  button {
    text-transform: uppercase;
    color: var(--white);
    text-decoration: underline;
    font-weight: 600;
    font-size: 1rem;
  }

  .spinner {
    animation: spin 1s infinite linear;
    transform-origin: center;
    display: grid;
    place-content: center;
  }

  @keyframes spin {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
</style>
