export const retryAfterMs = (response: Response) => {
  const retryAfter = response.headers.get('retry-after');
  if (!retryAfter) {
    return;
  }
  if (Number.isFinite(Number(retryAfter))) {
    return Number(retryAfter) * 1000;
  }
  const date = Date.parse(retryAfter);
  if (Number.isNaN(date)) {
    return;
  }
  return date - Date.now();
};
