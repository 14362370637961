<script lang="ts">
  import { nrkClose } from '@nrk/core-icons';
  import { closedCookieBanner } from '../util/cookie';

  const onClose = () => {
    closedCookieBanner.set(true);
  };

  let bannerElement = $state<HTMLDivElement | undefined>(undefined);

  $effect(() => {
    document.documentElement.style.setProperty('--cookie-banner-height', `0px`);
    if (!$closedCookieBanner && bannerElement != null) {
      document.documentElement.style.setProperty(
        '--cookie-banner-height',
        `${bannerElement.clientHeight}px`
      );
    }
  });
</script>

{#if !$closedCookieBanner}
  <div class="cookie-banner" bind:this={bannerElement}>
    <div>
      <strong>Vi bruker informasjonskapsler </strong>
      for å forbedre brukeropplevelsen.
      <a
        href="https://info.nrk.no/informasjonskapsler/"
        target="_blank"
        referrerpolicy="no-referrer"
      >
        Besøk vår informasjonsside
      </a>
      for mer detaljer.
    </div>
    <button aria-label="Lukk" onclick={onClose}>
      <!-- eslint-disable-next-line svelte/no-at-html-tags -->
      <div class="remove-prefix">{@html nrkClose}</div>
    </button>
  </div>
{/if}

<style>
  .cookie-banner {
    width: 100%;
    display: flex;
    justify-content: space-between;
    padding: 0.5rem;
    background-color: var(--purple);
    z-index: 1;
  }

  a {
    text-decoration: underline;
  }

  button {
    font-size: 1rem;
    padding: 0.25rem;
    background-color: transparent;
  }
</style>
