<script>
  export let showAnimation = false;
  export let empty = false;
</script>

<div
  aria-hidden="true"
  class="favorite-button__star"
  class:favorite-button__star--animate={showAnimation}
  class:favorite-button__star--empty={empty}
>
  {#if empty}
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 24 24"
      height="26"
      width="26"
    >
      <path
        fill="currentColor"
        fill-rule="evenodd"
        d="m12 4.044-1.435 4.88A1.5 1.5 0 0 1 9.125 10H3.94l3.882 2.986a1.5 1.5 0 0 1 .5 1.688l-1.8 5.104 4.591-3.368a1.5 1.5 0 0 1 1.774 0l4.592 3.368-1.801-5.103a1.5 1.5 0 0 1 .5-1.689L20.06 10h-5.186a1.5 1.5 0 0 1-1.439-1.077L12 4.044Zm-1.439-2.195c.422-1.436 2.456-1.436 2.878 0L15.25 8h6.28c1.433 0 2.05 1.816.916 2.689l-4.76 3.662 2.219 6.289c.491 1.391-1.112 2.581-2.302 1.708L12 18.24l-5.602 4.108c-1.19.873-2.793-.317-2.302-1.708l2.22-6.29-4.76-3.661C.42 9.816 1.038 8 2.47 8h6.282l1.809-6.151Z"
        clip-rule="evenodd"
      />
    </svg>
  {:else}
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 24 24"
      height="26"
      width="26"
    >
      <path
        fill="currentColor"
        d="M13.44 1.849c-.423-1.436-2.457-1.436-2.879 0L8.751 8h-6.28C1.037 8 .42 9.816 1.555 10.69l4.76 3.662-2.22 6.289c-.491 1.391 1.112 2.581 2.302 1.709L12 18.24l5.602 4.109c1.19.872 2.793-.318 2.302-1.71l-2.22-6.288 4.76-3.662C23.58 9.816 22.963 8 21.53 8h-6.282L13.44 1.85Z"
      />
    </svg>
  {/if}
</div>

<style>
  .favorite-button__star {
    position: relative;
    top: 0.5rem;
    color: white;
    font-size: 2rem;
    --size: 6rem;
    --speed: 0.7s;
  }

  .favorite-button__star--animate {
    animation: move var(--speed) ease-in-out;
  }

  .favorite-button__star--animate > svg {
    animation: change-star var(--speed) linear;
    fill: white;
  }

  .favorite-button__star--empty {
    fill: white;
    animation: none;
  }

  @keyframes change-star {
    0% {
      fill: transparent;
    }
    80% {
      fill: white;
    }
  }

  .favorite-button__star:nth-of-type(1) {
    --dx: 0;
    --dy: -1;
  }

  .favorite-button__star:nth-of-type(2) {
    --dx: 0.71;
    --dy: -0.71;
  }

  .favorite-button__star:nth-of-type(3) {
    --dx: 1;
    --dy: 0;
  }

  .favorite-button__star:nth-of-type(4) {
    --dx: 0.71;
    --dy: 0.71;
  }

  .favorite-button__star:nth-of-type(5) {
    --dx: 0;
    --dy: 1;
  }

  .favorite-button__star:nth-of-type(6) {
    --dx: -0.71;
    --dy: 0.71;
  }

  .favorite-button__star:nth-of-type(7) {
    --dx: -1;
    --dy: 0;
  }

  .favorite-button__star:nth-of-type(8) {
    --dx: -0.71;
    --dy: -0.71;
  }

  @keyframes move {
    0% {
      transform: scale(1);
    }
    50% {
      transform: scale(0.5)
        translate(calc(var(--dx) * var(--size)), calc(var(--dy) * var(--size)));
    }
    100% {
      transform: scale(1);
    }
  }
</style>
