<script>
  export let className = '';
  export let style = '';
  export let active = false;
</script>

<svg
  class={className}
  {style}
  aria-hidden="true"
  viewBox="0 0 116 123"
  fill="none"
  xmlns="http://www.w3.org/2000/svg"
>
  <path
    d="M57.7054 16.8212L98.9866 79.6987L81.7676 107H34.593L16.3751 79.6839L57.7054 16.8212Z"
    stroke="url(#msk_paint0_linear_fav)"
    stroke-width="2"
    fill={active ? '#00BACF' : '#070715'}
  />
  <g filter="url(#msk_filter0_f)">
    <path
      d="M57.7055 17.6373L98.7961 80.1007L81.797 107H34.5712L16.576 80.0711L57.7055 17.6373Z"
      stroke="url(#msk_paint1_linear)"
      stroke-width="4"
      fill={active ? '#00BACF' : '#070715'}
    />
  </g>
  <defs>
    <filter
      id="msk_filter0_f"
      x="0.175781"
      y="0"
      width="115"
      height="123"
      filterUnits="userSpaceOnUse"
      color-interpolation-filters="sRGB"
    >
      <feFlood flood-opacity="0" result="BackgroundImageFix" />
      <feBlend
        mode="normal"
        in="SourceGraphic"
        in2="BackgroundImageFix"
        result="shape"
      />
      <feGaussianBlur stdDeviation="7" result="effect1_foregroundBlur" />
    </filter>
    <linearGradient
      id="msk_paint0_linear_fav"
      x1="57.6758"
      y1="15"
      x2="57.1758"
      y2="86.5"
      gradientUnits="userSpaceOnUse"
    >
      <stop stop-color="#F5255B" />
      <stop offset="1" stop-color="#40E0F2" />
    </linearGradient>
    <linearGradient
      id="msk_paint1_linear"
      x1="57.6758"
      y1="14"
      x2="57.166"
      y2="87.0376"
      gradientUnits="userSpaceOnUse"
    >
      <stop stop-color="#F5255B" />
      <stop offset="1" stop-color="#40E0F2" />
    </linearGradient>
  </defs>
</svg>
