import * as Sentry from '@sentry/svelte';
import { regionIdMap } from '../lib/components/Location/regions';
import { HttpError } from './utils/HttpError';
import { unmaskedParticipants } from './api';
import { get } from 'svelte/store';
import { region } from './location.svelte';

type Percentage = (Maskorama.Percentage & { region: string }) | undefined;

const createStatisticsStore = () => {
  let percentage = $state<Percentage>(undefined);

  return {
    get percentage() {
      return percentage;
    },
    fetchPercentage: async () => {
      percentage = await getPercentage();
    },
  };
};

export const statistics = createStatisticsStore();

const getPercentage = async (): Promise<Percentage> => {
  if (region.location?.communeNumber == null) {
    return undefined;
  }

  let percentages = await getTotalPercentage();
  const correctGuessCount = get(unmaskedParticipants).filter(
    (participant) => participant.unmasked && participant.correct
  ).length;

  let percentage = percentages.find(
    (perc) => perc.correctGuesses === correctGuessCount
  );
  let currentLocation = 'Norge';

  if (percentage && percentage.betterThanPercent > 50) {
    return { ...percentage, region: currentLocation };
  }

  currentLocation =
    regionIdMap[region.location.communeNumber]?.name ?? currentLocation;
  percentages = await getCommunePercentage(region.location.communeNumber);
  percentage = percentages.find(
    (perc) => perc.correctGuesses === correctGuessCount
  );

  if (percentage && percentage.betterThanPercent > 50) {
    return { ...percentage, region: currentLocation };
  }
  return undefined;
};

const getTotalPercentage = async (): Promise<Maskorama.Percentage[]> => {
  try {
    const response = await fetch(
      `${import.meta.env.VITE_API_URL}api/guesses-percentiles`,
      {
        method: 'GET',
        headers: {
          'content-type': 'application/json',
        },
      }
    );
    if (!response.ok) {
      throw new HttpError(response);
    }
    return response.json();
  } catch (error) {
    Sentry.captureException(error);
    return [];
  }
};

const getCommunePercentage = async (
  communeId: number
): Promise<Maskorama.Percentage[]> => {
  try {
    const response = await fetch(
      `${import.meta.env.VITE_API_URL}api/guesses-percentiles/${communeId}`
    );
    if (!response.ok) {
      throw new HttpError(response);
    }
    return response.json();
  } catch (error) {
    Sentry.captureException(error);
    return [];
  }
};
