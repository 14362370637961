<script lang="ts">
  import noScroll from 'no-scroll';
  import { onMount } from 'svelte';
  import ConsentPage from '../../../pages/ConsentPage.svelte';
  import LocationPage from '../../../pages/LocationPage.svelte';
  import { trackEvent } from '../../util/snowplow';
  import {
    previousFocusElement,
    showModal,
  } from '../../util/stores/modalStore';
  import Feedback from '../Feedback/Feedback.svelte';
  import Info from './Info.svelte';
  import Locked from './Locked.svelte';
  import Thanks from './Thanks.svelte';

  let modalElement: HTMLDialogElement;

  $: if ($showModal) {
    if (modalElement != undefined) {
      if (document.activeElement instanceof HTMLElement) {
        previousFocusElement.set(document.activeElement);
      }
      trackEvent({
        action: `modal:open-${$showModal}`,
      });
      modalElement.showModal();
      noScroll.on();
    }
  }

  const focusPreviousElement = () => {
    if ($previousFocusElement != null) {
      $previousFocusElement.focus();
      previousFocusElement.set(null);
    }
  };
  $: if (!$showModal) {
    modalElement?.close();
    noScroll.off();
    queueMicrotask(focusPreviousElement);
  }
  const pagesWithoutCloseButton = ['location', 'consent'];

  onMount(() => {
    import('dialog-polyfill').then(({ default: dialogPolyfill }) => {
      dialogPolyfill.registerDialog(modalElement);
    });
  });
</script>

<dialog
  bind:this={modalElement}
  on:close={() => {
    showModal.set(undefined);
  }}
  class:is-closed={$showModal == undefined}
>
  {#if $showModal === 'info'}
    <Info />
  {:else if $showModal === 'locked'}
    <Locked />
  {:else if $showModal === 'thanks'}
    <Thanks />
  {:else if $showModal === 'feedback'}
    <Feedback />
  {:else if $showModal === 'location'}
    <LocationPage isModal={true} />
  {:else if $showModal === 'consent'}
    <ConsentPage isModal={true} />
  {/if}

  {#if !pagesWithoutCloseButton.includes($showModal ?? '')}
    <div class="primary-button__container">
      <button
        aria-label="Lukk"
        class="primary-button"
        on:click={() => {
          modalElement.close();
        }}
      >
        Lukk
      </button>
    </div>
  {/if}
</dialog>

<style>
  dialog {
    /*
    * Dialog styling - overriding user-agent width and height
    */
    width: 100%;
    height: 100%;
    max-width: 100%;
    max-height: 100%;
    padding: 0;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 2rem;
    background-image: linear-gradient(
      358.64deg,
      #6a1028 -3.73%,
      #2e173d 37.94%,
      #070714 83.79%
    );
    transition:
      display 120ms allow-discrete,
      overlay 120ms allow-discrete;
    display: none;
  }

  .primary-button__container {
    filter: unset;
  }

  dialog :global(p) {
    margin-inline: auto;
  }

  dialog.is-closed {
    animation: close 120ms forwards ease-in;
  }

  dialog[open] {
    animation: open 120ms forwards ease-out;
    display: flex;
    border: none;
    text-align: center;
  }

  @keyframes open {
    from {
      transform: translateY(100vh);
    }
    to {
      transform: translateY(0);
    }
  }

  @keyframes close {
    from {
      transform: translateY(0);
    }
    to {
      transform: translateY(100vh);
    }
  }

  button {
    margin-top: 3rem;
    color: black;
  }
</style>
